.domo-content p {
    font-size: 13px;
    
}


.domo-content section{
    font-family: "Avenir","Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji" !important;
}

.domo-content::after {
    display: grid;
    position: absolute;
    top:0;
    left:0;
    content:'';
    height: 100vh;
    width: 100%;
    background: linear-gradient( to bottom, #0b131800, #02020200, #00000071, rgb(0, 0, 0));
    z-index:-1;
}
.domo-content {
    display: grid;
    height: 100vh;
    width: 100%;
    background:transparent;
    position: relative;
    z-index: 0;
    grid-auto-flow: row;
    grid-template-rows: 8% 92%;
}

/* .head-bar-domo {
    border-bottom:1px solid #282828 !important;
} */

.body-bar-domo {
    padding-top:48px;
}

.login-box-domo {
    border-radius: 15px;
    max-width: 450px;
    width: 100% !important;
    padding: 16px;
    background: transparent;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-height: 391px;
height: 100%;
/* background: linear-gradient( to bottom, #0f0f0f, #0000002e); */
/* border: 1px solid #7f8da63b; */
/* box-shadow: 0px 0px 24px #000; */
}

.special-space {
    max-height: 429px !important;
}

.social-box-login-domo {
    border-radius: 8px;
    max-width: 375px;
    margin-top: 104px;
    padding: 16px;
    width: 100% !important;
    
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.container-social-domo {
    display: grid;
    grid-template-columns: 70% 10%;
    justify-content: space-between;
    background: #0f0f0f;
    max-width: 375px;
    width: 100% !important;
    padding: 8px 12px;
    border-radius: 3px;
    align-items: center;
    cursor: pointer;
    color: #fff;
    
}

.container-social-domo p {
    font-size: 16px;
    font-weight: 500;
}


.domo-input {
    border-style: none;
    border:1px solid #282828 !important;
    border-radius: 4px;
    height: 30px;
    box-shadow: 0px 0px 4px transparent;
    font-size: 18px;
    padding: 10px 7px;
    color: #f0f6ff;
    transition: all 200ms ease;
    background: transparent;
}

.domo-input:focus {
    border-color: #ffffff !important;
    transition: all 200ms ease;
    background: transparent;
}

.domo-input:hover {
    border:1px solid #e7ce7e !important;
    transition: all 200ms ease;
    background: transparent;
    
    
}
.domo-elemento-1{
    width: 100%;
    display: grid;
    grid-template-columns: 15% 85%;    
    margin-bottom:16px;
    column-gap: 4px;
}

.imagen-two h3 {
    color: #4d5461;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }

.imagen-two h2 {
    color: #ffe3c4;
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
  }

.domo-elemento-2 {
    width: 100%;
    display: grid;
    grid-template-columns:100%;
    row-gap: 7px !important;
}

.domo-elemento-2 p {
    cursor: pointer;
    color: #7f8da6;
    transition: all 200ms ease;

}

.domo-elemento-2 p:hover {
    color:#fff;
}


.imagen-one {
    display: grid;
    height: 38px;
    width: 38px;
    border-radius: 100px;
    background: #5164902f;
    border: 1px solid #e7ce7e;
}

.Active-lang-2 {
    text-shadow: 0px 0px 0px #fff;
    color: #fff;
    
    border-right: 1px solid #e7ce7e!important;
}

.Active-lang-2-left {
    text-shadow: 0px 0px 0px #fff;
    color: #fff;
   
    border-left: 1px solid #e7ce7e!important;
}


.border-radius-btn button:hover {
    background: #b300b3 !important;
    border: 1px solid #b300b3 !important;
}


.btn-domo:hover {
   text-shadow: 0px 0px 5px #fff;
    transition: all 200ms ease;
    border: 1px solid #e7ce7e !important;
}

.border-radius-btn {
    border-radius: 2px;
    bottom:0px !important;
}

.btn-domo {
    display: grid;
    /* background: linear-gradient(to right , #e61e4e, #d70466); */
    /* background: linear-gradient(to right , #9b7a46, #ffc961); */
    /* background: linear-gradient(to right , #59472a, #1e180c); */
    background:linear-gradient(to right , #0d0c08, #1e180c);
    border-style: none !important;
    border: 1px solid #a68954 !important;
    width: 100%;
    border-radius: 3px;
    color: #ffffffe9;
    /* border: 1px solid #faa404 !important; */
    padding: 17px 20px !important;
    cursor:pointer;
    position: relative;
    margin-top:40px;
    font-size: 18px;
}

.create-link-domo{    
    color: #7f8da6;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    margin-top: 54px;
    margin-bottom: 8px;
  }


  .create-domo  {
    color: #e7ce7e;
    cursor: pointer;
  } 
  .create-domo:hover {
    color:#fff;
    transition: all 200ms;
  }

  .hover-w:hover {
    color:#fff;
    transition: all 200ms;
    cursor: pointer;
  }

  .box-input-domo-doble {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: center;
    column-gap: 14px;
  }

  .or-social-domo {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 24px;
    color: #fff;
  }


  .bar-flag-all-domo {
    display: grid;
    height: 20px;
    grid-template-columns: 10% 10% 10%;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    bottom:2%;
    right:0px;
    z-index: 99999;
    opacity: 1;
    } 


    .bar-flag-all-domo button {
        border-style:none;
        cursor: pointer;
        padding: 10px;
        transition: all 200ms ease;
        background: #2c303c !important;
        border-radius: 2px;
        color:#7f8da6;
        
    }

/* ////// maquetacion de admin domo ///// */

.domo-dash-container {
    display: grid;
    height: 100vh;
    width: 100%;
    position: relative;
    
}

.dashboard-container-domo {
    display: grid;
    grid-template-columns: 100%;
    height: 100vh;
    width: 100%;
    position: relative;
    align-items: start;
    overflow-y: scroll;
    background: #1e1e1e;
}

.nav-bar-domo {
    display: block;
    align-items: start;
    position: fixed;
    z-index: 9999999;
    width: 280px;
    height: 100%;
    left: 0px;
    right: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
    grid-template-columns: 100%;
    background: #1e1e1e;
    transition: all 200ms ease;
}

.dashboard-domo {
    max-width:100%;
    display: grid;
    width: 100%;
    /* padding-top: 30px; */
    /* border-left: 1px solid #000;
    border-right: 1px solid #000; */
    padding-left: 300px;
    margin-left: auto;
    margin-right: auto;
    height: 110%;
    font-family: "Mulish","Helvetica Neue","Mulish-Fallback-To-Arial",sans-serif,"Apple Color Emoji","Segoe UI Emoji" !important;
}

.base-bar{
    padding-left: 280px;
    width: 100%;
    display: grid;
        grid-template-columns:  80% 20%;
        
        justify-content: end;
        align-items: center;
    }

    .search-bar-woot button{
        background:#6666661f;
        border-style: none;
        padding: 10px 20px;
        border-radius: 0px 100px 100px 0px;
        border-left: 1px solid rgb(105, 105, 105);
      }

      .admin-bar-domo {
        width: 100%;
        position: relative;
        background: rgb(14, 14, 14);
        
        height: 72px;
        font-size: 14px;
        color: #fff;
        display: grid;
        grid-template-columns:  100%;
        
        justify-content: end;
        align-items: center;
        
    } 
    
   
    .hidebox {
        padding-left: 10px !important;
    }
    
    .showbar {
        left:-60% !important;
        opacity: 1;
        transition: all 500ms ease;
    }

    .killbar{
        z-index: -3;

    }


@media screen and (min-width:0px) and (max-width:992px) {

    .box-universal-option {
        height: 90% !important;
       
    }
    

    .admin-bar-domo{
        width: 100%;
        position: fixed;
        background:rgba(34, 34, 34, 0.93);
        height: 62px;
        font-size: 14px;
        color: #fff;
        display: grid;
        grid-template-columns: 100%;
        justify-content: end;
        align-items: center;
        bottom: 0px;
        z-index: 999999999;
      }
    .nav-bar-domo {
        display: block;
        align-items: start;
        position: fixed;
        z-index: 9999999;
        width: 280px;
        height: 100%;
        right: 0% !important;
        left: auto;
        box-shadow: 0 0 10px 0 rgba(179, 179, 179, 0.7);
        grid-template-columns: 100%;
        
        background:linear-gradient(to right, #4d4b4bd4,#000000b0);
    }

    .onlinedomo h3 {
        color: #fff;
    }

    .nav-bar-domo  p {
        color:#fff;
    } 

    .nav-bar-domo .active-btn-domo  p {
        color:rgb(43, 40, 40);
    } 

    .right {
        color:#fff
    }

    .showbar {
        right:20% !important;
        opacity: 0;
        transition: all 500ms ease;
    }


    .dashboard-domo {
        max-width:100%;
        display: grid;
        width: 100%;
        /* padding-top: 30px; */
        /* border-left: 1px solid #000;
        border-right: 1px solid #000; */
        padding-left: 10px;
        margin-left: auto;
        margin-right: auto;
        height: 110%;
        font-family: "Mulish","Helvetica Neue","Mulish-Fallback-To-Arial",sans-serif,"Apple Color Emoji","Segoe UI Emoji" !important;
    }

    .base-bar{
        padding-left: 20px;
        width: 100%;
        display: grid;
            grid-template-columns:  60% 40%;
            
            justify-content: end;
            align-items: center;
        }
        
        .search-bar-woot button{
            background:#6666661f;
            border-style: none;
            padding: 0px 0px;
            border-radius: 0px 100px 100px 0px;
            border-left: 1px solid rgba(255, 255, 255, 0.19);
          }
    
    
    
}

.nav-bar-head-domo {
    height: 72px;
    background: transparent;
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 0px;
    width: 64%;
    font-size: 15px;

    
  }

  .submenu-style {
    display: grid;
    grid-template-columns: 100%;
    margin-left: 35px;
    
    border-top: 1px solid rgba(255, 255, 255, 0.208);
    
  }

  .nav-bar-head-domo span {
    color: #8983ff;
  }

.nav-bar-head-domo .left {
    /* background: #ff00ff; */
    border-radius: 100px;
    background: rgb(160, 32, 240);
    height: 42px;
    width: 42px;
}

.head-list-domo {
    height: 100%;
    padding-top: 16px;

}

.head-list-footer {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20% ;
    width: 50%;
    
    grid-auto-flow: column;
    position: relative;
    padding-left: 24px;
}

.onlinedomo h3 {
    font-size: 12px !important;
    text-transform: uppercase;
    font-weight: 500;
    padding-left: 24px;
    letter-spacing: 0px;
    margin-bottom: 10px;
    margin-top: 21px;
    position: relative;
  }

  


  .onlinedomo h3::after{
    content: '';
    height: 6px;
    width: 6px;
    background: #2dd887;
    border-radius: 100px;
    position: absolute;
    left:107px;    
}

  .head-list-footer span {
            border-radius: 24px;
        width: 24px;
            height: 24px;
            background: rgb(215, 215, 215);
        }

.nav-bar-list-domo button {
    color: #fff;
    padding: 8px 1px;
    border-style: none;
    text-align: left;
    cursor: pointer;
    padding-left: 24px;
    background: transparent;
    font-size: 15px;
font-weight: 500;
font-family: "Avenir","Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji" !important;
}

.nav-bar-list-domo div {
    display: grid;
    grid-template-columns: 100%;
}

.hr-domo-list {
    height: 1px;
    width: 83%;
    border-style: none !important;
    background:rgba(255, 255, 255, 0.208);
    margin-top: 15px;
    margin-bottom: 15px;
}

.active-btn-domo::after{
    content: '';
    height: 100%;
    width: 4px;
    background: #000;
    position: absolute;
    top:0px;
    left:0px;    
    transition: all 150ms ease;

}





.active-btn-domo {
    background: #252525 !important;
    position: relative;
    transition: all 450ms ease;
}





.nav-bar-list-domo {
    display: grid;
    grid-template-columns: 100%;
    height:auto;
    
    
}


.domo-btn-out-yeah {
    width: 70px;
    padding: 8px 12px;
    font-size: 14px;
    background-size: 14px 14px;
    border-style: none;
    border-radius: 3px;
    background: transparent;
    border:1px solid transparent;
    color: #fff;
    cursor:pointer;
    margin:0px auto;
    transition: all 200ms ease;
    opacity: 0.50;
    animation: entradafxbtn 100ms ease 0ms 1 normal
  }
  .domo-btn-out-yeah:hover {
    transition: all 200ms ease;
    transform: scale(1.15);
    opacity: 1;
}

@keyframes entradafxbtn {

    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }



} 

.domo-btn-out:hover {
    transition: all 200ms ease;
    background: rgb(50, 50, 50);
}

.domo-btn-out {
        width: 70px;
        padding: 8px 12px;
        font-size: 14px;
        background-size: 14px 14px;
        border-style: none;
        border-radius: 3px;
        background: transparent;
        border:1px solid transparent;
        color: #fff;
        cursor:pointer;
        margin:0px auto;
      }

      .domo-btn-out-3 {
        max-width: 130px;
        padding: 8px 12px;
        font-size: 14px;
        background-size: 14px 14px;
        border-style: none;
        border-radius: 3px;
        background: #000;
        border:1px solid #fff;
        color: #fff;
        cursor:pointer;
        width: 100%;
      }

      .search-bar-woot {
        width: 100%;
        display: grid;
        grid-template-columns:80% 20%;
        max-width: 600px;
        margin: 0 auto;
      }


      @media screen and (min-width:0px) and (max-width:992px){
        .search-bar-woot {
            width: 100%;
            display: grid;
            grid-template-columns:80% 40%;
            max-width: 600px;
            margin: 0 auto;
          }
    
      }

      .search-bar-woot input{
        background: #6666661f;
        border-style: none;
        padding: 10px 20px;
        border-radius: 100px 0px 0px 100px;
        color: #fff;
      }
    
.active-btn-bar-pantalla {
    padding-left: 20px !important;
}

.narbar-open {
    margin-left:354px;
} 



.box-domo-container { 
    max-width: 700px;
    margin: 0 auto;
}

.Mighty-box {
    display: grid;
    grid-template-columns: 75% 20%;
    margin:20px 0px;
    color: #000;
    max-width: 100%;
    justify-content: center;
align-items: center;
}

.Mighty-box h2{
    font-size: 30px !important;
    font-weight: 900 !important;
    line-height: 1.2 !important;
    
}

.Mighty-box p{
    font-size: 16px;
    font-weight: 400;
    color: #4C5360;
    font-family:"Mulish","Helvetica Neue","Mulish-Fallback-To-Arial",sans-serif,"Apple Color Emoji","Segoe UI Emoji";

  }

.Mighty-box button {
    border-style: none!important;
    margin-top: 0px !important;
    align-items: center;
    min-width: 100px !important;
    padding: 13px 14px !important;
        font-size: 14px;
        line-height: 17px;
        margin-left: 25px !important;
        
} 




.welcome-wrap {
    display: flex;
    height: 600px;
    max-width: 700px;
    
    overflow-x: scroll;
    overflow-y: hidden;
}

.welcome-wrap .item {
    min-width: 110px;
    height: auto;
    line-height: 110px;
    text-align: center;
    display: grid;
    grid-template-columns: 100%;
    border: 1px solid #000;
    background: #2b0f58;


} 
.imagen-producto {
    
    background-size:cover;
} 

.box-post-domo {
    display: grid;
    background: #fff;
    height: auto;
    width: auto;
    border-radius: 8px 8px 0 0;
    padding: 24px 40px 16px 40px;
    border: 1px solid rgb(255, 255, 255);
    box-shadow:0px 0px 6px rgba(148, 102, 215, 0.19);
    transition: all 150ms ease;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 850px;
    margin-bottom:30px;
  }


  .post-container{

    height: 100%;


  }
.box-post-domo:hover {
    transition: all 150ms ease;
    box-shadow: 0px 0px 20px rgba(7, 115, 223, 0.19);
}

.box-post-domo img {
    width: 100%;
    height: auto;
}

.box-post-domo-auth img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
}


.box-post-domo-auth {
    display: grid;
    grid-template-columns: 10% 90%;
    height: auto;
    align-items: center;
    column-gap: 3px;
    margin:10px auto;
    width: 100%;
}

.box-post-domo-auth-super img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
}


.box-post-domo-auth-super {
    display: grid;
    grid-template-columns: 10% 90%;
    height: auto;
    align-items: center;
    column-gap: 3px;
    margin:30px 0;
    max-width: 560px;
    width: 100%;
}

.margin-ajust {
    margin:10px 0 !important;
}

.domo-dash-section-ultimante{
    display: grid;
    position:relative;
    grid-template-columns: 50% 50%;
    width:100%;
    background: rgb(231, 231, 231);
    height: 100vh;
}


/* /////////// aqui vamos a colocar los members ///// */

.scroll-container {
    overflow-x: scroll; /* agrega scroll horizontal */
    /* white-space: nowrap;  */
    /* evita que los elementos se acomoden en múltiples líneas */
    width: 100%;
    
  }
  
  .scroll-content {
    display: inline-flex; /* permite que los elementos se acomoden en una sola línea */
    padding: 20px 0px;
  } 

  .circle {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    margin: 2px;
    background: #ffffff0a !important;
  }

  .circle-list {
    display: flex;
    flex-wrap: nowrap; /* evita que los elementos se acomoden en múltiples líneas */
    scroll-behavior: smooth !important;
  }

  .cuadro {
    width: 226px;
    height: 226px;
    margin: 5px;
    border-radius: 1px;
    background-size: cover !important;
    background-position-x: 50% !important;
  }

  .cuadro-list {
    display: flex;
    flex-wrap: nowrap; /* evita que los elementos se acomoden en múltiples líneas */
    scroll-behavior: smooth !important;
  }

  .section-btn-content {
    display: grid;
    grid-template-columns: 3% 80% 3%;
    position: relative;
    width: 100%;
    /* margin-left: auto;
    margin-right: auto; */
    margin: 30px auto;
  }


.section-btn-content button {
    border-style: none !important;
    cursor: pointer;
    background: transparent;
    box-shadow: 0px 0px 4px #d2d7df24;
    color: rgb(215, 215, 215);
    border-radius: 5px;
    /* max-width: 30px;
    margin-left: auto;
    margin-right: auto; */
}


.font-titulo-cuento {
    margin-right: 30px;
font-size: 20px;
font-weight: 700;
} 

.font-sub-cuento {
    margin-top: 5px;
font-size: 14px;
max-width: 540px;
padding-left: 2px;
} 

.box-header-title {
    border-bottom:1px solid #dddddd3d;
    height: 70px;
    display: grid;
    align-items: center;
    margin-bottom: 0px;
    font-size: 30px;
    color:#fff;
}

.box-text {
    display: grid;
    position: relative;
    grid-template-columns: 100%;
    color: #fff;
    text-align: center;
    align-items: center !important;
    justify-content: center;
    height: 100%;
    
}

.box-text button {
    border-style: none;
    background: #2b0f58;
    box-shadow: none;
    padding: 4px 14px;
    margin: 1px 0px;
    transition: 200ms ease;
} 

.box-text button:hover {
    border-style: none;
    background: #7d3be7;
    box-shadow: none;
    padding: 4px 14px;
    
    transition: 200ms ease;
} 

.box-text p{
 font-size: 10px;
}

.box-text h3{
    width: 100% !important;
   }


   .box-center-container {
   
    width: 100%;
    margin: 0px auto;
    height: auto;
    display: flex;
   } 

   .box-center {
    margin:0px auto;
    margin-left: auto;
    margin-right: auto;
   }

   .box-post-dos {
    display: grid;
    grid-template-columns: 96%;
    justify-content: center;
   }

   .margin-6x {
    margin: 50px auto;
    
   } 

   .imagen-box-domo {

    display: flex;
    justify-content: center;
    border-top:1px solid rgb(239, 239, 239);
    padding-top: 50px;

   } 

   .imagen-box-domo img {
    width: 95%;
    border-radius: 9px;
   }

   .coments-mod {
    background: rgba(242, 242, 242, 0.259);
    padding:20px 40px;
    border-radius: 9px;
    margin: 20px auto ;
    transform: all 200ms ease;
   }

   .coments-mod:hover {
    transform: all 200ms ease;
    background: rgba(235, 231, 255, 0.575);
   
    cursor: pointer;
   }

   .coments-mod  img {
    border-radius:  50% 50%;
   }

   .id-coment {
    background: rgb(158, 83, 255);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    color: #fff;
    justify-content: center;
} 

.ml-5 {
    margin-left: 20px;
}



.grid-total input {
    width: 100%;
    padding: 5px;
}

.comments-d4 input {
    width: 95%;
    height: 60px;
    margin: 20px auto;


} 

.coments-mod-2{
    background:#fff;
    padding:20px 40px;
    border-radius: 9px;
    margin: 20px auto ;
    transform: all 200ms ease;
   } 

   .btn-right {
    display: grid;
    grid-template-columns: 100%;
    justify-content: right;
    width: 100%;
   } 
.com-info{
    display: grid;
    grid-template-columns: 50% 50%;
    position: relative;
    width: 50%;

margin: 11px auto;
left: 14px;
} 

.com-info-text{
    
    max-width: 95%;
    display: grid;
    margin: 0 auto;

   
}

.com-info span {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    max-width: 35px;
    font-weight: 900;
    
}

.com-info img {
    height: 13px;
    width: 13px;
}

   /* //// vamos a colocar las ventanas /// */

   .ventana-white {
    align-items: center;
    justify-content: center;
    display: grid;
    animation: bg-flash-black 1760ms ease 0ms infinite alternate !important;
    justify-content: center;
    
    
    
}

.section-maestro .main {

    display: block;
    width: 100%;
    height: 100%;
    
    grid-template-columns: 100%;
    position: relative !important;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.104);
   
    
}

.section-maestro {
    width:100%;
    height: 100%;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    width: 80vw;
    height: 70vh;
    max-width: 1200px;
   
}

.box-title-universal {
    padding: 10% 15% 0% 15%;
}

.form-box {
    padding: 2% 15%;
    display: grid;
    grid-template-columns: 100%;

}


.list-ajust {
    display: grid;
    grid-template-columns: 100%;
    align-items: start;
    text-align: center;
    padding: 20px;
    border-radius: 7px 0px 0px 10px;
}

.list-ajust-2 {
    display: grid;
    grid-template-columns: 100%;
    align-items: start;
    text-align: center;
    padding: 20px;
    border-radius: 0px 7px 7px 0px;
}

.text-white {
    color: #fff
}

.color-white {
    background: #fff !important;
}

.color-gray {
    background: linear-gradient(to bottom, #010101 , #210053 )!important;
}

.color-domo {
    background: linear-gradient(to bottom, #854cda , #210053 )!important;
}

.Heigh-100 {
    height: 100%;
}

@keyframes bg-flash-black {
    0% {
        
        
        background:  rgba(28, 28, 28, 0.594);
        transition: all 150ms ease;
        

    }
    100% {
        background: rgba(0, 0, 0, 0.782);
        transition: all 150ms ease;
    } 
}

.open-opacity-fx-JIMF {
    animation: open-opacity-fx 360ms ease 0ms 1 normal !important;
}


@keyframes open-opacity-fx {
    0% {
        
        
       opacity: 0;
        transition: all 150ms ease;
        

    }
    100% {
        opacity: 100;
        transition: all 150ms ease;
    } 
} 

.input-domo-ultra {
    display: grid;
    grid-template-columns: 100%;
    height: auto;
    row-gap: 2px;
    margin: 10px 0px;
}

.input-domo-ultra input {
    border-style: none;
    border-bottom:1px solid rgb(255, 255, 255);
    padding:10px 2px;
    font-size: 20px;
    background: transparent;
    color:#fff;
}


.input-domo-ultra label {
    border-style: none;
    font-size: 30px;
}

.input-domo-ultra button {
    border-style: none;
    font-size: 20px;
    padding:10px 2px;
    cursor: pointer;
    background: #210053;
    transition: all 200ms ease;
}

.input-domo-ultra button:hover {
    border-style: none;
    font-size: 20px;
    padding:10px 2px;
    cursor: pointer;
    background: #7237ca;
    transition: all 200ms ease;
    
}

.btn-domo-ultra {
    border-style: none !important;
    cursor: pointer;
    border-radius: 1px;
    background: rgba(255, 255, 255, 0.15);
    color:#fff;
    border: 1px solid rgb(255, 255, 255) !important;
    transition: all 200ms ease;
    padding: 5px 13px;
}

.btn-domo-ultra:hover {
    transition: all 200ms ease;
    background: rgba(239, 239, 239, 0.294);
}

.add-btn-domo {
    display: grid;
    background: #252525 !important;
    color: #fff;
    transition: all 200ms ease;
    position: relative;
}

.add-btn-domo:hover{
    transition: all 200ms ease;
    color: #fff;
}

.add-btn-domo::after {
    background: rgba(0, 0, 0, 0.616);
    content:"";
    height: 100%;
    width: 100%;
    top:0px;
    left:0px;
    position: absolute;
    display: block;
    transition: all 200ms ease;
    opacity: 1;
    z-index: -1;
}

.add-btn-domo:hover::after {
    background: transparent;
    display: block;
    content:"";
    height: 100%;
    width: 100%;
    top:0px;
    left:0px;
    position: absolute;
    transition: all 200ms ease;
    opacity: 0;
    
}


.add-btn-domodos {
    display: grid;
    background: #252525a3 !important;
    color: #fff;
    transition: all 200ms ease;
    position: relative;
}




.box-universal-option {
    max-width:100%;
    display: grid;
    width: 100%;
    /* padding-top: 30px; */
    /* border-left: 1px solid #000;
    border-right: 1px solid #000; */
    padding-left: 281px;
    padding-top: 72px;
    margin-left: auto;
    margin-right: auto;
    height: 110%;
    overflow-x: none;
    overflow-y: scroll;
    font-family: "Mulish","Helvetica Neue","Mulish-Fallback-To-Arial",sans-serif,"Apple Color Emoji","Segoe UI Emoji" !important;
}


.d-block{
    display: block;
}

.bg-domo-joema-option {
    background: linear-gradient( to left bottom, #f4f6fa, #f4f6fa);
    padding-left: 19px;
    padding-bottom: 10%;
} 


.domo-option-back{
    
    animation: animation-option 1860ms ease 0ms infinite alternate !important;

  } 

  .domo-option-back::before {
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    content:'';
    top:0px;
    left:0px;
    filter:blur(1000px);
    background:  linear-gradient( to  right, #f4f6fa,#f4f6fa,#f4f6fa);
  }



  @keyframes animation-option  {
    0%{
        background: rgba(0, 0, 0, 0.775);
        transition: all 550ms ease;
    }
    100% {
        background: rgba(0, 0, 0, 0.835);
        transition: all 550ms ease;
    }
  }


  .animation-open-option{
    
    animation: animation-option-fx 460ms ease 0ms 1 normal !important;

  } 

  @keyframes animation-option-fx  {
    0%{
       opacity: 0;
       transform: scale(2);
        transition: all 250ms ease;
    }
    100% {
        transform: scale(1);
        transition: all 250ms ease;
    }
  }

  .animation-open-option-close{
    animation: animation-option-fx-out 960ms ease 0ms 1 normal !important;
  }

  @keyframes animation-option-fx-out  {
    0%{
      
       transform: scale(1);
       opacity: 1;
        transition: all 250ms ease;
    }
    100% {
        transform: scale(0.90);
        opacity: 0;
        transition: all 250ms ease;
    }
  }

  .op-none{
    opacity: 0;
    transform: scale(2);
    transition: all 250ms ease;
    animation: buenavainaop 300ms ease-in-out 0ms 1 normal !important;
  }

  .op-true{
    opacity: 1;
    transform: scale(1);
    transition: all 200ms ease;
    animation: buenavaina 240ms ease 0ms 1 normal;
  } 

  @keyframes buenavaina {
    from{
        opacity: 0;
        transform: scale(2);
        transition: all 250ms ease;
    }
    to{
        opacity: 1;
        transform: scale(1);
        transition: all 250ms ease;
    }
  }

  @keyframes buenavainaop {
    from{
        opacity: 1;
        transform: scale(1);
        transition: all 180ms ease;
    }
    to{
        opacity: 0;
        transform: scale(1);
        transition: all 180ms ease;
    }
  
  }

  .transform-lejos{
    transform: scale(0);
    /* top:-300%;
   left:-200%; */
   transition: all 600ms ease;
   opacity: 0;
   
  } 

  .bar-closet {
    height: 72px;
    background: linear-gradient( to right, #2e2d55, #bbb0ff);
    display: grid;
    grid-template-columns: 70% 30%;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    margin-left: 281px !important;
    position: absolute !important;
    top:0px !important;
    z-index: 9999;
    width: 100%;
    

  } 

  .bar-closet-2 {
    width: 281px;
    background: transparent;
    display: grid;
   
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    position: absolute !important;
    top:0px !important;
    
    height: 100vh;
    

  } 

  .btn-domo-closet-win {
    
    border-radius: 100px;
    background: rgba(240, 229, 247, 0.149);
    height: 42px !important;
    width: 42px !important;
    display: block;
    border-style: none;
    text-align: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    transition: all 100ms ease;
    opacity: 0.90;
    padding:0px;
    
  }

  .btn-domo-closet-win:hover{
    
    transition: all 100ms ease;
    opacity: 1;
    transform: rotate(-90deg);
    
    
  } 


  .box-post-domo-window {
    display: grid;
    background: transparent;
    height: auto;
    width: 100%;
    border-radius: 8px 8px 0 0;
    
    border: 1px solid rgb(255, 255, 255);
    box-shadow:0px 0px 6px rgba(148, 102, 215, 0.19);
    transition: all 150ms ease;
    align-items: center;
    
    max-width: 600px;
    margin-bottom:30px;

  }

  .box-center-window {
    /* margin: 0px auto; */
    margin-left: 3%;
    margin-right: 45%;
  }

  @media screen and (min-width:0px) and (max-width:992px) {
    .box-center-window {
        /* margin: 0px auto; */
        margin-left: auto;
        margin-right: auto;
      }

      .box-universal-option{
        padding-left: 0px!important;
      }

      .bg-domo-joema-option {

        padding-left: 20px;
        padding-bottom: 10%;
        padding-right: 20px;
      }

      .section-coment-post-coment {
        grid-template-columns: 10% 60% 30% !important;
      }

      .box-post-domo-auth  {
        grid-template-columns: 30% 70% !important;
      }
  }

  .font-comments-mayor {
    font-size: 10px;
  }

  .imagen-box-domo-2 {

    display: flex;
    justify-content: center;
    
    padding-top: 0px;

   } 

   .imagen-box-domo-2 img {
    width: 100%;
    border-radius: 9px;
   }

   .reaction-box-action {
    display:grid;
    grid-template-columns: 33% 33% 33%;
    text-align: center;
    align-items: center;
    padding: 0px 0px;
    column-gap: 2px;
    
background: #ececec;
   }

   .reaction-box-action button {
    border-style: none;
    cursor: pointer;
    padding: 15px 0px;
    background: #f4f6fa;
    transition: all 200ms ease;
    font-size: 15px;
    color:#706f70;
    border-radius: 3px;
   }
   .reaction-box-action button:hover {
    
    background: #fff;
   transition: all 200ms ease;
   }

   .reaction-box {
    display:grid;
    grid-template-columns: 40% 40%;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: 20px 0px;
    border-bottom: 1px solid #706f7021;
    
   }

   .group-reaction{
    display:grid;
    grid-template-columns: 50% 50%;
    text-align: center;
    align-items: center;
    padding: 1px 0px;
    column-gap: 2px;
   } 


   .imagen-coment {
    height: 30px;
    width: 30px;
    border-radius: 50%;
   }

   .section-coment-post-coment{
    display:grid;
    grid-template-columns: 10% 67% 23%;
    text-align: center;
    align-items: center;
    padding: 45px 20px;
    column-gap: 4px;
    margin-bottom: 25px;
    
   } 

   .section-coment-post-coment input {
    border-style: none;
    padding: 10px 25px;
    border-radius: 20px;
    background: #f4f6fa;
    border: 1px solid #cacaca;
    box-shadow: 0px 3px 2px #aeaeae2e;
   }

   .box-post-dos-comment {
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    position: relative;
   }

   .margin-boder{
    margin-left:20px !important;
    margin-right:20px !important;
   }

   .icon-c-domo{
    height: 15px;
    width:15px;
    
   }
   .icon-btn-c-domo{
    padding: 10px 15px ;
    display: grid;
    grid-template-columns: 100%;
    column-gap: 5px;
    align-items: center;
    justify-content: center;
    width: 100% !important; 
    max-width: 45px !important;
    margin: 0px auto;
    font-size: 15px;
    color:#706f70;
    
   }
   

   .icon-btn-c-domo-box{
    border-radius: 20px;
    border-style: none;
    cursor: pointer;
    box-shadow: -1px 3px 1px #2424242e;
    background: #fff;
    transition: all 200ms ease;
    
   }

   .icon-btn-c-domo-box:hover {
    transition: all 200ms ease;
    background: rgb(45, 0, 86);
   }

  
   .box-post-domo-auth-dos img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}


.box-post-domo-auth-dos {
    display: grid;
    grid-template-columns: 20% 80%;
    height: auto;
    align-items: center;
    column-gap: 3px;
    margin:30px auto;
    width: 100%;
    padding: 0px 10px;
}

.box-post-domo-auth-3 {
    display: grid;
    grid-template-columns: 90% 10%;
    height: auto;
    align-items: start;
    column-gap: 3px;
    margin:0px auto;
    width: 100%;
    padding: 0px 10px;
   
}

.comments-box{
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 0px;
    margin-top: 10px;
}
.id-coment-2 {
    background: rgba(221, 221, 221, 0.335);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    color: #131313;
    padding: 4px;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid #fff;
} 
.scroll-coments {
    max-height: 100%;
    
    overflow: hidden;
    position: absolute;
    width: 100%;
    /* background: linear-gradient( to bottom, #ffffffde, #fffffff0); */
    background:linear-gradient( to bottom, #06060636, #000000c7);
    bottom:275px;
    border-radius: 10px;
    border: 1px solid #fff;
    color:#fff;
   
   
}

.coments{
    overflow-y: scroll !important;
    max-height: 304px;
    height: 100%;
    margin: 10px 3px;
    
}
.box-chat-x {
    background: rgba(255, 255, 255, 0.24);
    border-radius: 12px;
    padding: 10px 20px;
    border: 1px solid #fff;
    transition: all 200ms ease;
    box-shadow: 0px 0px 2px #fffffff1;
}

.box-chat-x:hover{
    background: rgba(255, 255, 255, 0.576);
    transition: all 200ms ease;
}

.yeaheven:nth-child(even) {
    margin-left: 20px;
    
}

.box-box-chat-y{
    width: 180px;

}

.box-chat-y {
    display: grid;
    grid-template-columns: 33% 33% 33%;
   width: 100%;
    padding: 7px 20px;
    text-align: center;
    font-weight: 300;
    font-size: 13px;
    
  }


  .imagen-online {
    height: 100%;
    width:100%;
    border: 1px solid rgb(165, 165, 165);
    border-radius: 100px;
  }

  .list-menu-icon-set {
    display: grid !important; 
    grid-template-columns: 10% 80% !important;
   
  }

  .icon-menu-box{
    height: 20px;
    height: 20px;
  }


  .actualizareldomo{
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    max-width: 800px;
    column-gap: 3px;
    width: 100%;
    margin: 10px 0px;
  }
  .actualizareldomo label {
    text-align: right;
    font-size: 14px;
    padding: 4px 40px;
    background: transparent !important;
    height: 30px;
    display: grid;
    align-items: center;
    color: #2b0f58;
    border-radius: 40px 0px 0px 40px;
  }

  .actualizareldomo input {
    padding: 4px 40px;
    border-style: none;
    border-radius: 0px 100px 100px 0px;
    height: 30px;
    background: rgba(255, 255, 255, 0.156);
    border: 1px solid #fff;
    
  }


  .actualizareldomo-3{
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    max-width: 800px;
    column-gap: 3px;
    width: 100%;
    margin: 10px 0px;
  }
  .actualizareldomo-3 label {
    text-align: left;
    font-size: 24px;
    padding: 4px 40px;
    background: transparent !important;
    height: 30px;
    display: grid;
    align-items: center;
    color: #fff;
    border-radius: 40px 0px 0px 40px;
  }

  .actualizareldomo-3 input {
    padding: 4px 40px;
    margin-left: 30px;
    border-style: none;
    border-radius: 0px 100px 100px 0px;
    height: 30px;
    color: #fff;
    background: rgba(255, 255, 255, 0.156);
    border: 1px solid #ffffff61;
    
  }

  .W-100 {
    width: 100%;
  }

  .submit-act-datos{
    display: grid;
    grid-template-columns: 20%;
    align-items: center;
    justify-content: end;
    max-width: 800px;
    column-gap: 3px;
    width: 100%;
    margin: 10px 0px;
  }

  .blurmax {
    filter: blur(6px) !important;
  }

  .blurmax2 {
    filter: blur(2px) !important;
  }


  .necio {
    display: grid;
    position: absolute;
    top:0%;
    left:0px;
    content:'';
    background: rgb(255, 255, 255);
    width: 100%;
    height: 5px;
    z-index: 3;

   

  }

  .necio::after {
    display: grid;
    position: absolute;
    top:0%;
    left:0px;
    content:'';
    background: linear-gradient( to left, rgba(238, 236, 255, 0.637), rgba(255, 255, 255, 0.596));
    width: 100%;
    height: 5px;
    z-index: 9;
    opacity:1;
    animation: moveloaddomo 1000ms ease 0ms infinite normal !important;
    border-radius: 0px 100px 100px 0px;
  }

  .necio::before {
    display: grid;
    position: absolute;
    top:0%;
    left:0px;
    content:'';
    background: linear-gradient( to right, rgb(80, 12, 251), rgb(65, 13, 198));
    width: 100%;
    height: 5px;
    z-index: 2;
    opacity:1;
    animation: moveloaddomo 7000ms ease 0ms infinite normal !important;
    border-radius: 0px 100px 100px 0px;
  }


  .box-necio {
    position: relative;
  }

  @keyframes moveloaddomo {
    0%{
        width:0%;
        
    }
    100% {
        width:100%;
        
    }
  }

  .opacity-none {
    opacity: 0;
    transition: all 200ms ease;
  
  }

  .animation-opacity {
    opacity: 1;
    transition: all 200ms ease;
  }

  .btn-select-domo {
        color:#706f70 !important;
        background: rgba(230, 230, 230, 0.289) !important;
  }

  .btn-select-domo img {
    fill : #fff !important;
    stroke: 10px !important;

  }

  .yeahmaster::after{
    content:'';
    height: 100px;
    width: 100px;
    background: rgba(0, 0, 0, 0.975);
    position: absolute;
    left:50%;
    display: grid;
    justify-content: center;
    border-radius:100%;
    animation: xpan 300ms ease 400ms infinite normal !important;
  }

  .yeahmaster::before{
    content:'';
    height: 100px;
    width: 100px;
    background: rgba(226, 226, 226, 0.291);
    position: absolute;
    left:50%;
    display: grid;
    justify-content: center;
    border-radius:100%;
    animation: xpan2 250ms ease 400ms infinite normal !important;
  }


  @keyframes xpan {
    0%{
        transform: scale(1.0);
    }
    100%{
        transform: scale(9.0);
        opacity: 0;
    }
}

@keyframes xpan2 {
    0%{
        transform: scale(1.0);
        opacity: 1;
    }
    100%{
        transform: scale(27.0);
        opacity: 0;
    }
}


.box-interest{
    display: grid;
   
    
    background: #000;

  height: 200px;
  width: 200px;
  position: relative;



}

.vamosactualizar{
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  
}

.W-mor{
    width: 100%;
    max-width: 600px;
    
}

.subgroup {

    width: 100%;
    height: 100%;
    position: relative;
}
.clasico-box-interes {
   
    height:auto;
    width:100%;
    margin: 0px auto;
    grid-template-columns: 50% 50%;
    display: grid;
    align-content: start;
    justify-content: center;
    column-gap: 10px;
    row-gap: 5px;
    padding: 20px;
}

.active-btn-domo-ne {
    background: #2b0f58 !important;
    color:#fff;
    border-style: none;
    border-radius: 100px;
    cursor: pointer;
    transition: all 200ms ease;
    padding: 10px 20px;
    width:100%;
   
   

} 

.inactive-btn-domo-ne {
    background: #fff !important;
    color:rgb(41, 38, 38);
    border-style: none;
    border-radius: 100px;
    cursor: pointer;
    transition: all 200ms ease;
    padding: 10px 20px;
    width: 100%;
}

.vamor {
    width:100%;
}

.OpenDesk {
    display: grid;
    grid-template-columns:100%;
    text-align: center;
   
    max-width: 70%;
    margin: 0 auto;
    padding: 30px;
}

.OpenDesk h2 {
    font-size: 40px;
}




.Container-desk {
    height: 400px;
    width: 100%;
    display: grid;
    align-items: center;
    position: relative;
    background: #252525;
    border-radius: 2px;
    margin: 0px auto;
    max-width: 100%;
    padding: 24px 40px 16px 40px;
    color:#fff;
    border-radius: 0px;

}

.search-feed {
    background: rgba(118, 118, 118, 0.181);
    margin: 10px auto;
    border-radius: 100px;
    width: 100%;
}


.container-desk {
    height: 600px;
    width: 100%;
    display: grid;
    align-items: center;
    position: relative;
    /* background:linear-gradient( to right top, #3b0065, #53146d); */
    border-radius: 2px;
    margin: 20px auto;
    max-width: 75%;
    padding: 24px 40px 16px 40px;
    color:#fff;
    border-radius: 10px;
}

.container-desk  button {
    background: transparent !important;
    box-shadow: none;
}

.Container-desk  button {
    background: transparent !important;
    box-shadow: none;
}



.dddd {
    background: transparent;
}

.mantion {
    height: 386px;
} 


.container-members {
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: 100%;
}


.circle-mortal {
    width: 156px;
    height: 156px;
    margin: 5px;
    border-radius: 9px;
    background-size: cover !important;
    background-position-x: 50% !important;
    border-radius: 100%;
  } 


  .button-eject-add-group {
    display: grid;
    grid-template-columns:  50% 50%;
  } 


  .button-eject-add-group  button {
    border-style: none;
    background:#ffffff0f !important;
    color: #fff;
    cursor: pointer;
    max-width: 500px;
    margin: 0 auto;
    padding: 2px 30px;
    white-space: nowrap;
    transition: all 200ms ease;
  }

  .button-eject-add-group  button:hover {
    background:#11fa8948 !important;
    transition: all 200ms ease;
  } 