.atlasbank-admin {
    display: grid;
    
    grid-auto-flow: row;
    grid-template-rows: 20% 80%;
    row-gap: 20px;
    overflow-x: none !important;
    background: rgb(0, 13, 29) !important;
    height: 100vh !important;
    padding:20px !important;
    color: #fff !important;
    position:relative !important
}






.atlasbank-admin-one {
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 10px;
    border:1px solid  rgba(255, 255, 255, 0.185);
    column-gap:10px;
    position: fixed;
    width: 100%;
    align-items: center;
    background-size:  100%;
    background-blend-mode:hard-light;
    /* background-position: 50% 10% !important; */
    animation: bg-yeah-cool-joema 30000ms ease 0ms infinite normal;

}

@keyframes bg-yeah-cool-joema {
    from {
        background-position: 50% 0% ;
        
        
    }
    to {
        background-position: 50% 100%;
        
    }
}


.atlasbank-admin .atlasbank-admin-dos {
    border:1px solid rgba(216, 216, 216, 0.322);
    box-shadow:10px -3px 5px #62626247;
} 

.admin-header-1 {
    border-right:1px solid #3d93b5;
    display:grid;
    grid-template-columns: 100%;
    width: 100%;
    align-items: center;
    padding: 20px ;
}

.admin-header-2 {
    display:grid;
    grid-template-columns: 100%;
    width: 100%;
    margin:0px;
    align-items: center;
    height: 70px;
}


.admin-header-2 h1, h2, h3, h4, p {
    margin:0px;
}

.admin-header-2 small {
    border-top:1px solid #3d93b542;
    color: #3d93b5 !important;
    
}


.agent-name-title {
    font-weight: 300 !important;
    color:#3d93b5;
}

.atlas-color-primary {
    color:#3d93b5;
}

.agent-name {
    color: #fff;
    font-weight: 900;
}

.admin-header-1 img {
    display: grid;
    width: 100%;
    
}

.absolute-btn {
    display: grid;
    position: relative;
}


/* /////////////////////////////// aqui vamos a colocar los estilos de el body ///// */

.box-clientes {
    display: grid;
    border:0px solid rgba(255, 255, 255, 0.26);
    height: 550px;
    background: #0000004d;
    grid-template-rows:10% 80%;
    grid-auto-flow: row !important;
    position: relative;
}

.email-text {
    text-transform:lowercase !important; 
}


.search-bar-admin {
    border-bottom:1px solid rgb(146, 255, 222) !important;
    /* margin-bottom: 10px !important; */
    background: rgb(10, 10, 10) ;
    display: grid;
    grid-template-columns:20% 80%;
    padding: 1px !important;
    align-items: center !important;
}

.searchbar-input  {
    border-radius: 2px !important;
    background: linear-gradient(to right, rgb(1, 13, 36), #000) !important;
    box-shadow: none !important;
    color: rgb(146, 255, 222) !important;
    font-weight: 400 !important;
    font-size: 17px !important;
    border: 1px solid rgb(146, 255, 222) !important;
    
  }
.lista-clientes {
    border:1px solid rgba(255, 255, 255, 0.26);
    background: #0000004d;
}

.atlasbank-admin-dos {
    padding:30px;
}

.atlasbank-admin-dos h2 {
    background-blend-mode:hard-light;
    text-align: right;
    height: 10%;
    padding: 20px 20px 20px 20px;
    display: grid;
    align-items: center;
    background-size: 150% !important;
    background-position: 30% 20% !important;
    background-repeat: repeat;
    transition:all 200ms ease !important;
    text-shadow: 2px 2px 0px #000 !important;
    
}

.atlasbank-admin-dos h2 small {
    font-size: 15px;
    font-weight: 300;
    color:#00b7ff;
    text-shadow:1px 2px 0px #000 !important;
}


.lista-clientes {
    position:relative;

}


.lista-clientes-box .header {
    display: grid;
    grid-template-columns:8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8%;
    padding: 20px 0px;
    justify-content: center;
    column-gap: 2px !important;
    width: 100%;
    position: relative;
    height: 40px;
    border-bottom: 1px solid #fdfdff71;
    

}

.lista-clientes-box .header h5 {
    display: grid;
    text-align: center;
    margin:0px;
    align-items: center;
    font-weight: 400 !important;
    font-size: 16px;
    border-right:1px solid rgba(255, 255, 255, 0.233);
    padding: 0px 10px;
    color: rgb(146, 255, 222) !important;   
}




:root{
    scrollbar-color: rgb(222, 229, 234) rgb(46,54,69) !important;
    scrollbar-width: thin !important;
    
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8c04fc42;
  }

   .container-acordeon::-webkit-scrollbar-thumb {
        background: #fff !important;
    }
    
.lista-clientes-box .body:hover {
    /* background: rgba(255, 255, 255, 0.093); */
    background: rgb(46,54,69) ;
    border-bottom:1px solid rgb(146, 255, 222);
    transition:all 300ms ease ;
    color: rgb(146, 255, 222);
    transform: scale(0.98);
}


.lista-clientes-box .body {
    color: rgb(129, 129, 129);
    display: grid;
    grid-template-columns:8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8%;
    width: 102%;
    
    padding: 20px 0px;
    justify-content: center;
    text-emphasis: center !important;
    border-bottom:1px solid rgba(255, 255, 255, 0.119);
    cursor: pointer;
    
}

.lista-clientes-box .body div {
    overflow: hidden !important;
    font-size: 17px !important;
    text-align: center !important;
    text-transform: capitalize !important;
}

.body-box {
    
    height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
    
    
}


.float-report {
    display: grid;
    position: absolute;
    width: 70%;
    top: 21%;
    left: 20px;

    height: 100px;
    
    grid-template-columns: 20% 20% 20% 20% 20%;
    z-index: 22;
    column-gap: 20px;
    
}

.float-report .box-report {
    border: 1px solid rgba(255, 255, 255, 0.244);
    background: #000;
    grid-auto-flow: row;
    grid-template-rows: 70% 30%;
    text-align: center;
    display: grid;
    align-items: center;
    padding: 20px;
    /* background-blend-mode:color-dodge; */
    background: linear-gradient(to left top, rgb(18, 32, 31), rgba(5, 15, 32, 0.747), rgba(0, 0, 0, 0)), url(../imagenes//background/admin.jpg);
    background-size:600%;
    
}

.float-report .box-report:hover{
    transform: scale(0.96);
    border: 1px solid  rgb(146, 255, 222);
}


.animation-duo {
    animation: abraham 13000ms ease-in-out 0ms infinite alternate;
}

.animation-trio {
    animation: abrahamM 13000ms ease-in-out 0ms infinite alternate;
    position: relative;
}

.animation-trio::after{
    position: absolute;
    content: '';
    top:0px;
    right:0px;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom left, rgba(0, 0, 0, 0.342), rgba(0, 0, 0, 0.068), rgba(52, 143, 115, 0.123), rgba(32, 104, 88, 0.479));
    z-index: 0;
    opacity: 0;
    animation: abrahamF 2000ms ease-in-out 0ms infinite alternate;
}

.box-right span {
    box-shadow: none!important;
}




.animation-trio h1 , h3 {
    z-index: 1;
    position: relative;
}




@keyframes abrahamF {

    from {
        opacity: 0;
    }

    to{
        opacity: 1;
    }
}

@keyframes abrahamM {

    from {
        background-position:50% 100%;
        background-size:600%;
    }

    to{
                background-position:50% 0%;
                background-size:100%;
    }
}


@keyframes abraham {

    from {
        background-position:50% 0%;
        background-size:100%;
    }

    to{
        background-position:50% 100%;
        background-size:600%;
    }
}




.box-report h1, h3 {
    margin: 0;
    display: grid;
    align-items: center;
}


.box-report h1 {
    font-size: 40px;
}


.war {
    color: rgb(255, 239, 150)
}

.green{
    
    /* color: rgb(113, 255, 122); */
    color: rgb(146, 255, 222);
    letter-spacing: 2px;
}

.normal {
    color:#727272;
}


/* //// aca vamos a colocar el menu admin ///// */


.admin-menu {
    display: grid;
    position: fixed;
    z-index: 99;
    left:0px;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    width: 100%;
    height:100%;
    border-style: none !important;
    background: linear-gradient( to top, rgba(0, 0, 0, 0), #000, #000) !important;
    border:1px solid #383838 !important ;
}




.admin-form-box {
    display: grid;
    grid-template-columns: 100%;
    height: auto;
    min-height: 200px;
    width: 50%;
    margin-left:auto;
    margin-right:auto;
    
}

.admin-form-box .container-form  {
    border:1px solid rgb(146, 255, 222);
}

.admin-form-box .input-element select {
    color: #e7ebed !important;
    cursor: pointer !important;
    background: rgb(15, 15, 15) !important;
  }

.admin-form-box input  {
    box-shadow: none !important;
    background: transparent !important;
    color: #fff !important;
}

.admin-form-box  select {
    box-shadow: none !important;
    background: transparent !important;
    color: #fff !important;
}
.admin-form-box label {
    box-shadow: none !important;
    background: rgb(15, 15, 15) !important;
    color: #fff !important;
}

.admin-form-box div {
    background: rgb(15, 15, 15) !important;
    color: #fff !important;
}




.admin-form-box h2 {
    color: #fff;
    text-align:center;
}

.admin-form-box h2 small {
    color:rgb(74, 179, 255);
}


.admin-menu img {
    background: #000;
    display: none;
}

.admin-menu button:hover {
        transition: all 200ms ease;
        color: rgb(146, 255, 222) !important ;
        background: rgb(32, 32, 32);
        
}

.admin-menu button {
    transition: all 200ms ease;
    border:1px solid #383838 !important;
    cursor:pointer !important;
    background: #000 ;
    color: #fff;
}


.admin-head {
    display: grid;
    grid-template-columns: 33.0% 33.0% 33.0%;
    column-gap: 7px;
    
}


.box-client-admin-information {
    display: grid;
    background: rgba(32, 32, 32, 0.27);
    width: 100%;
    height: 100px ;
    grid-template-columns:40% 40%;
    justify-content: space-between;
    position: absolute;
    top:0px;
    align-items: center;
    padding: 20px;
}



.admin-menu button {
    position:relative;
}

.admin-body {
    margin-top: 6px;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 7px;
    
} 

.logo-modal {
    display: grid;
    justify-content: center;
    align-items: center;
}

.logo-modal img{
    height:auto;
    width: 200px;
    display: grid;
    background: transparent;
}



.box-left {
    display: grid;
    grid-template-columns: 50% 50%;
}

.box-right:hover .box-icon-toggle {
    border:1px solid rgba(146, 255, 222, 0.349) !important;
}
.box-right:hover .box-icon-toggle span {
    background: rgb(146, 255, 222) !important;
}


.header-modal {
    border-left:1px solid #3d93b5 !important;
    padding-left: 20px;

}

.header-modal small {
    color: rgb(146, 255, 222) !important;
    
}