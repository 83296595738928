.jimf-col-3 {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    background: linear-gradient( to bottom, rgb(0, 40, 140), rgb(4, 95, 193));
    height: 100%;
    width: 100%;
    column-gap: 10px;


}

.pilastra {
    background: #fff;
    position: relative;
}


.pilastra-2 {
    background: radial-gradient(circle at center, rgb(0, 171, 255) ,rgba(108, 102, 102, 0), rgb(0, 9, 36));
    position: relative;
}

.Kit-Menu {
    display:grid;

}


.bg-particle-media-black-round #tsparticles {
    height:99% !important;
    opacity: 0.9;
}


.bg-particle-media-black-round::after{
    height: 100%;
    width: 100%;
    position: absolute;
    content:'';
    top: 0px;
    left: 0px;
    /* background:linear-gradient(to top left ,rgba(26, 57, 115, 0.46) ,rgba(0, 0, 0, 0), rgba(7, 194, 241, 0.2)); */
    background:radial-gradient(circle at center, rgb(0, 171, 255) ,rgba(108, 102, 102, 0), rgb(0, 9, 36));
    
    z-index: 0;
}

.bg-particle-media-black-round{
    display: grid;
    z-index: 0;
}


.bg-style-3 {
    background-repeat: repeat !important;
    background-position: 50% 50% !important;
    background-size: cover !important;
} 


.bg-style-3::after{
    height: 100%;
    width: 100%;
    position: absolute;
    content:'';
    top: 0px;
    left: 0px;
    background:radial-gradient(circle at center, rgba(1, 40, 125, 0.937) ,#0015397c, rgba(27, 84, 255, 0));
    z-index: 0;
} 

.pilastra-3 {
    display: grid;
    align-items: center;
    height: 100%;
}

.Logo-3x {
    display: grid;
    justify-content: center;
}

.Logo-3x img {
    display: grid;
    margin-top:10px;
    height: 120px;
    border: 1px solid rgba(255, 255, 255, 0.205);
    padding: 20px;
    z-index: 900000;
}


/* /////// kit menu ///// */


.kitmenu {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    height: 100%;
    background: #fff;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid #04518c4e !important;
    
    box-shadow: 0 1px 6px rgba(32,33,36,.28);
    
    
} 


.kitmenu .text-kit-menu {
    display: grid;
    
    margin: 0px 0px !important;
    padding:10px !important;

} 



.text-kit-menu p {
    margin:0 !important;

    font-weight: 800 !important;
    line-height: 28px;
    font-size: 25px;
    padding: 5px;
    color: #04508c !important;
}

.text-kit-menu span {
    border-top:1px solid #04508c;
    margin-top: 4px;
    padding-top: 9px;
    padding-left: 6px;    
    font-size: 11px;

}


.kitmenu .header {

    display: grid;
    grid-template-columns: 33% 33% 33% ;
    justify-content: center;
    column-gap: 5px;
    margin-top: 2px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    

}


.alert-icon-war {
    
    display: grid;
    position: absolute;
    width: 25px;
    height: 25px;
    
    top:-10px;
    left:-10px;
    background: rgb(255, 255, 255);
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    padding: 6px;
    z-index: 999999;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.219);
    /* border: 1px solid rgb(226, 202, 15); */
}

.alert-icon-sus {
    
    display: grid;
    position: absolute;
    width: 25px;
    height: 25px;
    
    top:-10px;
    left:-10px;
    background: rgb(255, 255, 255);
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    padding: 6px;
    z-index: 999999;
    /* border: 1px solid rgb(15, 226, 78); */
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.219);
}







.kitmenu .body {

    display: grid;
    grid-template-columns: 49.5% 49.5%;
    justify-content: center;
    column-gap: 5px;
    margin-top: 2px;
    padding-left: 20px;
    padding-right: 20px;
    
}


.kitmenu button {
    display: grid;
    align-items: center;
    border-style: none!important;
    background: #fff;
    border-radius: 2px;
    position: relative;
    height: 70px;
    
    
    cursor:pointer;
    box-shadow: 0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    font-size: 14px !important;
    border-left:1px solid #d8d8d85c !important;
    border-right:1px solid #d8d8d85c !important;
    border-top:1px solid #d8d8d85c !important;
    border-bottom:3px solid #f2f2f2 !important;
    transition: all 200ms ease !important;
    transform: scale(0.93);

} 



.kitmenu button:focus {
    display: grid;
    align-items: center;
    border-style: none!important;
    background: #fff;
    border-radius: 2px;
    
    
    cursor:pointer;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.075);
    font-size: 14px !important;
    border-left:1px solid #d8d8d85c !important;
    border-right:1px solid #d8d8d85c !important;
    border-top:1px solid #d8d8d85c !important;
    border-bottom:3px solid #4cc1d3 !important;
    transition: all 200ms ease !important;
    transform: scale(0.93);

} 



.kitmenu button h3 {
    font-weight: 600 !important;
    line-height: 14px;
    padding: 5px;
    color:#04508c !important;
    font-size: 15px;
}

.kitmenu button:hover {
    display: grid;
    align-items: center;
    border-style: none!important;
    background: #fff;
    border-radius: 2px;
    transition: all 150ms ease;
    transform: scale(1);
    border-left:1px solid #d8d8d85c !important;
    border-right:1px solid #d8d8d85c !important;
    border-top:1px solid #d8d8d85c !important;
    
    border-bottom:3px solid #0165b1bb !important;
    transition: all 200ms ease !important;
} 



.head {
    display: grid;
    justify-content: center;
    width: 100%;
    border-bottom:1px solid #04518c22;
    text-align: center;
} 

.head  h1 {
    font-weight: 600 !important;
    line-height: 28px;
    font-size: 30px;
    padding: 5px;
    color: #fff !important;
    text-shadow: 0px 0px 4px rgb(1, 56, 255);
    /* color: #04508c !important; */
}

.BlueA {
    height: 30px !important ;
    
    margin-top:20px !important;
    

    
}


.nav-jimf {
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    width: 100% !important;
    position: relative;
    justify-content: space-between;
    


}



.nav-bar-joel-fixed img {
    height: 40px !important;
    
}

.nav-jimf img {
    height: 20px;
    text-align: center;
    
}
 

.nav-bar-joel-fixed img {
    height: 40px !important;
    
} 

.nav-bar-joel-fixed strong {
    color:#ffffff;
}



.nav-bar-joel-fixed {
    display: grid;
    height: 100%;
    grid-template-columns: 15% 50% 35%;
    justify-content: space-between;
    padding: 10px 0px 10px 0px;
    border-bottom:1px solid #ffffff2b;
    /* background: linear-gradient( to right,#132962ac,#010b1f); */
    color: #fff;
    background-position: 50% 50%;
    background-size:cover !important;
    background-repeat:no-repeat !important;
    


} 

.nav-bar-joel-fixed button {
    border-style: none;
    border-radius: 2px !important;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.535);
    color: rgb(182, 182, 182);
    font-weight: 300;
    cursor: pointer;
    outline: none;
    border-left:1px solid #d8d8d85c !important;
    border-right:1px solid #d8d8d85c !important;
    border-top:1px solid #d8d8d817 !important;
    border-bottom:1px solid #d8d8d817 !important;
    background: transparent;
    transition: all 200ms ease !important;
    padding: 5px 30px;
    margin-right: 10px;
    
    box-shadow: 0 1px 6px rgba(32,33,36,.28);

}


.nav-bar-joel-fixed button:hover {
    transition: all 150ms ease;
    color: #fff;
    
    border-left:1px solid #d8d8d85c !important;
    border-right:1px solid #d8d8d85c !important;
    border-top:1px solid #d8d8d810 !important;
    border-bottom:1px solid #fff !important;
    
}





 .nav-bar-joel-fixed div {
        align-items: center;
        display: flex;
        justify-content: center;
 }
/* //// vamos a hacer el blank aqui /// */

.bar-info-close img {
    height: 25px;
} 

.bar-info-close strong {
    color:#04508c;
}



.bar-info-close {
    display: grid;
    height: 0px;
    grid-template-columns: 20% 33% 20%;
    justify-content: space-between;
    padding: 6px;
      padding-bottom: 6px;
    border-bottom: 1px solid #04508c;
    margin-bottom: 10px !important;
    padding-bottom: 30px !important;
  }

  @media screen and (min-width: 0px) and (max-width: 992px) {

    .bar-info-close {
            position: fixed;
            width:100%;
            display: grid;
            top:7vh;
            height: 0px;
            grid-template-columns: 20% 33% 20%;
            justify-content: space-between;
            padding: 6px;
            padding-bottom: 6px;
            border-bottom: 1px solid #04508c;
            margin-bottom: 10px !important;
            padding-bottom: 30px !important;
            z-index: 9999999999 !important;
            background: #fff;
    }


    .container-form {
        display: block;
        padding-top: 8vh;
        background: #fff;
        width: 100%;
        height: 100%;
        position: relative;
      }


  }

.bar-info-close button {
    border-style: none;
    border-radius: 2px !important;
    box-shadow: 0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    color: #04508c;
    font-weight: 600;
    cursor: pointer;
    outline: none;
    border-left:1px solid #d8d8d85c !important;
    border-right:1px solid #d8d8d85c !important;
    border-top:1px solid #d8d8d85c !important;
    border-bottom:3px solid #f2f2f2 !important;
    background: #fff;
    transition: all 200ms ease !important;
    transform: scale(0.93);
    

}


.bar-info-close button:hover {
    transition: all 150ms ease;
    transform: scale(1);
    border-left:1px solid #d8d8d85c !important;
    border-right:1px solid #d8d8d85c !important;
    border-top:1px solid #d8d8d85c !important;
    border-bottom:3px solid #0165b1bb !important;
    transform: scale(1);
}





 .bar-info-close div {
        align-items: center;
        display: flex;
        justify-content: center;
 }

.container-form {
    display: block;
    background: #fff;
    width: 100%;
    height:100%;
    position: relative;
    
    
} 

.animation-hi {

    animation : hellow 200ms ease 0ms 1 normal;

}


@keyframes hellow {
    from {
        transform:scale(0.97)
        
        
    }
    to {
        transform:scale(1)
        
    }
}

    




.container-form-window {
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 15px;
    padding-top: 5px;
    min-height: 455px;
    position: relative;
} 

.container-form-tittle {
    display: grid;
    justify-content: center;

} 


.container-form-tittle h2 {
    font-size: 20px;
    margin:0 !important;
}

.container-form-tittle p {
    font-size: 10px;
    margin:0 !important;
} 


.container-form-footer {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 19px 20px !important;
    background: rgb(246, 246, 246);
    border-top:1px solid #04508c;
}


@media screen and (min-width:0px) and (max-width:992px) {

.container-form-footer {
    position: fixed;
    width: 100%;
    bottom:0px !important;
}


}

.btn-atlas {
    border-style: none;
    border-radius: 2px !important;
    box-shadow: 0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    color: #04508c;
    font-weight: 600;
    cursor: pointer;
    outline: none;
    border-left:1px solid #d8d8d85c !important;
    border-right:1px solid #d8d8d85c !important;
    border-top:1px solid #d8d8d85c !important;
    border-bottom:3px solid #f2f2f2 !important;
    background: #fff;
    transition: all 200ms ease !important;
    transform: scale(0.93);
    
    padding: 6px 0px !important;

} 


.btn-atlas:hover {
    transition: all 150ms ease;
    transform: scale(1);
    border-left:1px solid #d8d8d85c !important;
    border-right:1px solid #d8d8d85c !important;
    border-top:1px solid #d8d8d85c !important;
    border-bottom:3px solid #0165b1bb !important;
    transform: scale(1);
}
 


.container-form-tittle strong {
    border-top:1px solid rgb(201, 201, 201);
    padding-top: 3px;
    margin-top: 3px;
    width: 16%;
    color:#04508c;
} 


.container-form-tittle h2 {
    color:#04508c;
} 


.cursor-disabled {
    cursor:not-allowed !important;
    filter:saturate(0%);
}

.opacity-50{
    cursor: not-allowed !important;
    border:1px solid #0bfd4f !important;
}

.BoxTittle {
    display: grid;
    text-align: center;
    /* background: linear-gradient( to right, rgba(14, 119, 255, 0.982), #04508c); */
    background: linear-gradient( to right, rgba(14, 118, 255, 0), #04508c);
    color: #fff;
    font-weight: 500;
}

.dream-text-x1 {
    font-size:8px;
    color: #04508c;
    text-align: center;
    margin:0px ;
}

.mb-1 {
    margin-bottom:3px;
} 

.mx-1 {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
}


.mx-3 {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}

.mt-5 {
    margin-top:50px;
}

.border-one {
    border-top:1px solid #04508c !important;
    padding-top: 10px !important;
}

.top-0  {
    display: grid !important;
    top:0 !important;
    width:100% !important;
    text-align: center;
}

.fix-son-2 {
    display: grid;
    grid-template-columns: 47% 47%;
    column-gap: 5px;
    width: 100%;
    justify-content: space-between;
}

.fix-son-3 {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 5px;
    width: 100%;
    justify-content: space-between;
}

.input-element-2 input {
    cursor: pointer;
    
}

.input-element-2 {
    display: grid;
    grid-template-columns: 70% 20%;
    font-size: 12px;
    padding-bottom: 10px;

    align-items: center;
    justify-content: center;
    border-bottom:1px solid #04508c ;
    

}



.dream-text-2 {
    font-size: 25px;
    font-weight: 700;
    color: #04508c;
}

.d-grid {
    display: grid;
}

.align-center {
    align-items: center;
}

.text-center {
    text-align: center !important;
}

.dream-text-border {
    display: grid;
    position: relative;
    border-bottom:1px solid #04508c;
    color: #04508c;
    padding-bottom: 2px ;
    padding-top: 2px ;
    padding-left:10px;
    background: linear-gradient( to left, #fff, rgba(2, 34, 85, 0.033));
    font-size: 9px;

}

.add-ben-atlas-container {
    background: #fff;
    position:relative;
    border-bottom: 1px solid #d2d2d2;
    margin-top: 1px;
    padding: 0px 0px 10px 0px;
    
}

.add-ben-atlas-container-beta {
    display: grid;
    white-space: nowrap;
    
    
    font-size: 20px;
    font-weight: 800;
    
    background: #04508c;
    border: 2px;
    color: #fff;
    
    width: 30px;
    margin-left:  auto;
    
    height:  30px;
    align-items: center;
    right:10px;
    
    cursor:pointer;
    border-radius: 2px;
    
    
    
    
} 

.add-ben-atlas-container-beta:hover {
    transition:all 200ms ease;
    background: #032e4f;
} 


.disabled-class {
    filter: saturate(0%) blur(3px);
    opacity: 0.80;
    cursor: not-allowed !important;
    transform: none !important;
    
}

.disabled-class:after  {
    height: 100px;
    background: rgba(255, 255, 255, 0.227);
    width: 100%;
    content: "";
    position: absolute;
    top:0px;
    right:0px;
}

.yeah-cool::after {
    position: absolute;
    content: "";
    bottom:0;
    height: 70%;
    width:100%;
    z-index: 0;
    background:  linear-gradient(to top, #fff, rgba(255, 255, 255, 0.79))

} 


.kitmenu {
    z-index: 2;
    position: relative;
} 

.index-z {
    z-index: 2;
    position: relative;
}


.dream-text-3 {
    font-size:9px;
    margin: 0px !important;
    letter-spacing: 1px;
} 


.hr::after{
    content:'';
    display: block;
    height: 100%;
    width: 40%;
    left:0px;
    position: absolute;
    background: #fdfdfd;
}


.hr::before{
    content:'';
    display: block;
    height: 100%;
    width: 40%;
    right:0px;
    position: absolute;
    background: #ffffff;
}

.hr {
    display: block;
    height:1px;
    width: 100%;
    background: transparent;
    margin: 3px 0px 13px 0px;
    position:relative;

}

.hijo-hr {
    position:absolute;
    right:38%;
    color:#ffffff;
    background: transparent;
    top:-10px;
    width: 100px;
    text-align: center;
    
}



/* ///////////////////////////////// aqui vamos a colocar los estilos del mainnav ///////// */
/* @media screen and (min-width:0px) and (max-width:992px) {
    .main-nav{
        height:720px !important;
    } 

    
} */


.main-nav {
    height: 100vh;
    width: 100%;
    /* background: linear-gradient( to top, rgb(0, 57, 107), rgba(147, 163, 255, 0)); */
    position: relative;
    z-index: 999;
    border-right: 2px solid #4c80d336;
    display: grid;
    grid-auto-flow:row !important;
    grid-template-rows: 15% 85%;
    row-gap: 1px;
}

.main-nav div {
    /* box-shadow: 0px 0px 3px rgba(147, 183, 255, 0.501); */
    border-radius: 2px;
    margin: 10px 2px 10px 2px;
    /* border:1px solid rgba(255, 255, 255, 0.144); */
}

.main-nav button:hover {
    color: rgb(255, 255, 255);
    border: 1px solid rgba(255, 255, 255, 0.331);
    border-left: 20px solid transparent ;
}

.active-main-nav:hover{
    filter: blur(2px);
}
.active-main-nav {
    border-left: 20px solid transparent !important;
    border-right: 1px dashed #b3ffdf !important;
    border-top: 1px solid #b3ffdf !important;
    border-bottom: 1px solid #b3ffdf !important;
    color: rgb(255, 255, 255) !important;
}

.btn-layer{
        
        
        text-align: left;
        cursor: pointer;
        padding: 0px 20px;
        color: rgb(187, 211, 255);
        
        border: 1px solid rgba(255, 255, 255, 0.03);
        position: relative;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: -1px;
        transition: all 300ms ease;
        animation : rightmotion 700ms ease-in-out 3ms 1 normal;
        
    }

    @keyframes rightmotion{
        from{
            border-left: 80px solid transparent ;
            opacity: 0;
        }

        to{
            border-left: 1px solid rgba(255, 255, 255, 0.03) ;
            opacity: 1;
        }
    }

    @keyframes leftmotion{
        from{
            border-right: 280px solid transparent ;
            opacity: 0;
        }

        to{
            border-right: 1px solid rgba(255, 255, 255, 0.03) ;
            opacity: 1;
        }
    }





.active-main-nav small{
    color: #b3ffdf !important;
}

.main-nav button {
    display: grid;
    align-items: center;
    border-style: none;
    border-radius:1px;
    background:rgba(255, 255, 255, 0.03);
    text-align: left;
    cursor: pointer;
    padding: 0px 20px;
    color: rgb(187, 211, 255);
    text-shadow:0px 0px 4px #000;
    
    border: 1px solid rgba(255, 255, 255, 0.03);
    position: relative;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: -1px;
    transition: all 300ms ease;
    
}

.main-nav p {
    display: grid;
    color: #fff;
    font-size: 10px;
    padding: 0px 27px;
    border-bottom: 1px solid #fff;
    align-items: center;
    
}

.main-nav button small {
margin: 0px !important;
padding: 0px !important;
font-size: 10px;
position: absolute;
bottom:5px;
right: 4px;
color: rgb(90, 161, 254);
font-weight: 400;
letter-spacing: 0px;
transition: all 300ms ease;

}

.main-nav button:hover small{
    color:#fff;
    transition: all 100ms ease;
    }


    

.atlaslayerout {
    
    display: grid;
    grid-template-columns: 18% 40% 42%;
    background: linear-gradient( to bottom, rgb(0, 40, 140), rgb(4, 95, 193));
    height: 100%;
    width: 100%;
    column-gap: 10px;

}

@media screen and (min-width:0px) and (max-width:992px) {
    .atlaslayerout{
        grid-template-columns: 100%;
    } 

    .nav-bar-joel-fixed{
     grid-template-columns: 20% 33% 20%;   
    }
    .main-nav-option-btn {
        /* grid-template-columns: 22% 22% 22% 22% !important; */
        column-gap: 10px;
    }
}

.main-nav-option-btn {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 10px;
    padding: 0px 4px 60px 4px;
    
}


.main-nav-option-btn-controller {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 10px;
    padding: 10px 24px;
}

.main-nav-option-btn-controller button{
    display: grid;
    height: 100%;
    position: relative;

}

/* ///////////////////////////////// aqui vamos a colocar los estilos del mainnav ///////// */


.box-control{
    background: #001539;
    height: 300px;
    width: 300px;
}




/* //// vamos a colocar los textos ////// */
.descargue{ 
    border: 1px solid #04508c;
}

.alerta-master {
    width: 320px;
    height:100px;
    background: #00152a;
    position:fixed;
    top:70px;
    right:30px;
    z-index:999999;
    padding: 9px;
    text-align: center;
    border:3px solid #b4f9d7;    
    box-shadow: 2px 2px 10px #00ff8458;
    justify-content: center;
    border-radius:4px;
    color: #e3faef;
    font-weight: 700;
    opacity: 1;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px #042c72;
    /* animation : animation-alert 1000ms linear 0ms 1 alternate; */
}

@keyframes animation-alert {

    0% {
        right:-30px;
        opacity: 0;
    }
    30% {
        right:30px;
        opacity: 1;

    }

    80% {
        right:30px;
        opacity: 1;

    }

    100% {
        right:-30px;
        opacity: 0;
    }


}

.alerta-master-danger {
    border:3px solid #ff0051 !important;  
}


.container-alerta-master {
    
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
}

.alerta-master p {
    
    font-size: 20px;
    width: 250px;
    text-align: center;
    margin:10px auto;
}

.arrow-joe-container:hover {
    background:rgb(45, 45, 45);
    box-shadow: 0px 0px 7px #000;
    transition: all 200ms ease;
    border:1px solid #0bffb2;
}


.arrow-joe-container  {

    display: grid;
    height:30px;
    width:30px;
    background: rgb(24, 24, 24);
    box-shadow: 0px 0px 7px transparent;
    border:1px solid #000;
    border-radius: 3px;
    position: absolute;
    top: 10px;
    z-index: 99999;
    left:10%;
    transition: all 200ms ease;

}

.arrow-joe-container .elemento-one-arrow {    

    height:60%;
    width:1px;
    position: absolute;
    display: grid;
    top: 10%;
    left: 50%;
    background: #0dffb27c;
    z-index: 0;
    animation: bajada 1000ms ease 0ms infinite normal;
    border-radius: 100px;
}


@keyframes tileo {
            from {
                opacity: 0.10;
        }
        to {
            opacity: 1;
        }

}

@keyframes bajada {

    from {
                height: 10%;
    }
    to {
        height: 65%;
    }



}


.arrow-joe-container .elemento-dos-arrow {
    border-radius: 100px;
    background: #0bffb2;
    height: 30%;
    left: 60%;
    bottom:10%;
    width: 1px;
    display: grid;
    position: absolute;
    transform: rotate(45deg);
    z-index: 1;
    animation: tileo 500ms ease 0ms infinite alternate;
}


.arrow-joe-container .elemento-tres-arrow {
    border-radius: 100px;
    background: #0bffb2;
    height: 30%;
    right: 60%;
    bottom:10%;
    width: 1px;
    display: grid;
    position: absolute;
    transform: rotate(-45deg);
    z-index: 1;
    animation: tileo 500ms ease 0ms infinite alternate;
}


.admin-logout button:hover {
    border-bottom:1px solid #fff;
    transition: all 200ms ease;
    color: #0bffb2;
}

.admin-logout button {
    border-style: none;
    border-radius: 2px;
    padding: 20px;
    cursor: pointer;
    background: #000;
    color: #fff;
    border:1px solid transparent;
}

.admin-logout {

    display: grid;
    position: absolute;
    top:0;
    background: #000;
    z-index: 99999999999999;
    right: 1%;

}

/* 
//// aqui vamos a colocar los estilos de las cosas
cuando se activan  ////// */

.admin-aprobado {
   color: rgb(8, 223, 116);
   animation: colorloop 3000ms ease-in 0ms infinite alternate !important; 
}

.admin-rechazado {
    color: rgb(255, 0, 93);    
 }

 @keyframes colorloop {
    from {
        filter: saturate(100%);
    }
    to {
        filter: saturate(200%);
    }
}

.admin-legal {
    color:rgb(243, 232, 171);
}
.admin-person {
    color : rgb(98, 203, 172);
}



@keyframes colorloop {
    from {
        filter: saturate(0%);
    }
    to {
        filter: saturate(200%);
    }
}

[money]{
	position: absolute;
    top:30%;
    right: 0px;
    height: 100%;
    width: 100%;
    

}

[money] input{
	position: relative;
    width: 100%;
}

[money]::before {
    font-weight: 300;
	content: attr(money);
	color: #000;
    align-items: center;
    display: grid;
	text-align: center;
	font-size: 15px;
    bottom:0px;
    z-index: 99999; 
    cursor: pointer;
    
} 

