/* //// estilos de la banca en linea //// */
@media screen and (min-width:0px) and (max-width:992px) {


    .form-login-banca-linea .Logo{
        margin-top: 6vh;
        margin-bottom: 6vh;

    }



  .jimf-card-banca {

    border: 1px solid rgba(0, 0, 0, 0.07);
    width: 100%;
    position: relative;
    display: grid;
    align-items: start;
    padding-top: 5vh;
   
    
    
    
  }



  .forgot-banca {
    display: grid;
    margin-top: 4vh;
    margin-bottom: 4vh;
    color: #fff;
    font-size: 16px;
  }


  
  .form-login-banca-linea {
    box-shadow: 0 1px 6px rgba(32,33,36,.28);
    border-radius: 3px;
    height: 100%;
    width: 90%;
    z-index: 25;
    position: relative;
    animation: entrada-2 600ms ease 0ms 1 normal;    
    margin-left: auto;
    margin-right: auto;
}


.bg-style-banca::after {
    height: 100%;
    width: 100%;
    position: absolute;
    content: '';
    top: 0px;
    left: 0px;
    background: linear-gradient( to bottom, #003984,#0057b6);
    z-index: 0;
  }

  .input-element-banca {
    display: grid;
    width: 100%;
    grid-template-columns: 10% 70% 20%;
    background: #fff;
    padding: 15px;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    font-size: 15px !important;
    margin-bottom: 10px;
    

    
  }


  .input-element-banca input {
    border-style: none !important;
    font-size: 15px !important;

  }



























}





