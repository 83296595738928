
html {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    box-sizing: border-box !important;
    font-family: 'Lato',Arial,Helvetica,sans-serif;
    color: #706f70;
    background: #000 !important;
    height: 100vh;
    position: relative;
}

@media screen and (min-width:0px) and (max-width:992px) {
    html {
        overflow-y: scroll !important;
        overflow-x: none !important;
    }
}


body {
    padding: 0;
    margin: 0;
}


div {
    box-sizing: border-box !important;
    transition: all 200ms ease ;
}


/* /////// aqui voy a colocar la barra de navegacion /////  */


    .nav-jimf {
        display: grid;
        position: fixed;
        height: 30px;
        width: 100%;
        background:  #fff;
        text-align: center;
    }






.contenedor {
    display: grid;
    background: #fff;
    height: 100vh;
    width: 100vw;   
    position: relative;
    
    
} 

.animation-container {
    animation: loadopenjoel 900ms ease 0ms 1 normal;
}

@keyframes loadopenjoel {
    from {
        
        filter: blur(20px) hue-rotate(-20deg);
        opacity: 0.50;
    }
    to{
        filter: blur(0px) hue-rotate(0deg);
        
        opacity: 1.00;
    }
}



.jimf-col-2 {
    display: grid;
    grid-template-columns: 50% 50%;
    position: relative;
    
}

.jimf-card {
    /* border: 1px solid rgba(0, 0, 0, 0.07); */
    
    width: 100%;   
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
}



.form-login {
    box-shadow: 0 1px 6px rgba(32,33,36,.28);
    border-radius: 3px;
    height: 100%;
    width: 420px;
    /* padding: 50px; */
    z-index: 25;
    /* background: linear-gradient( to bottom, #ffffffef, #fff); */
    /* background: linear-gradient(to bottom , rgba(223, 223, 223, 0.21),rgba(0, 0, 0, 0.064), #061c30); */
    position: relative;
    /* border:1px solid rgba(255, 255, 255, 0.3); */

    animation: entrada-2 600ms ease 0ms 1 normal;
    
    

}

.jimf-register-container {
    display: grid;
    justify-content: center;
    background: linear-gradient(to top, #fff,#fff,#fff, #fff);
}

.position-absolute {
    position: fixed;
    display: grid;
    bottom:0;
    height: 100vh;
    width: 100%;

}

.form-register {
    display: grid;
    
    border-radius: 3px;
    height: auto;
    align-items: center;
    
    width: 480px;
    padding: 10px;
    z-index: 25;
    
    position: relative;

    

    animation: entrada-2 600ms ease-in-out 0ms 1 normal;
    
    

}



@keyframes entrada-2  {
    from {
        transform: translateX(-100px) scale(0.94);
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateX(0px) scale(1.00);
    }
} 


.bg-style-2 {
    background-repeat: repeat !important;
    background-position: 50% 0% !important;
    background-size: contain !important;
} 


.bg-style-2::after{
    height: 100%;
    width: 100%;
    position: absolute;
    content:'';
    top: 0px;
    left: 0px;
    background:linear-gradient(to bottom right, rgb(0, 171, 255) ,rgba(108, 102, 102, 0), rgb(0, 9, 36));
    z-index: 0;
}


.bg-style {
    background-repeat: repeat !important;
    background-position: 50% 0% !important;
    background-size: contain !important;
} 


.bg-style::after{
    height: 100%;
    width: 100%;
    position: absolute;
    content:'';
    top: 0px;
    left: 0px;
    background: radial-gradient(circle at center, rgb(0, 5, 8) ,rgba(10, 10, 10, 0.31), rgb(1, 1, 2));
    /* background:radial-gradient(circle at center, rgb(0, 171, 255) ,rgba(108, 102, 102, 0), rgb(0, 9, 36)); */
    z-index: 0;
}


.bg-primary {
    background-color: #034e8c;
}


.bg-white {
    background:#fff;
}

.Logo {
    display: grid;
    justify-content: center;
}

.Logo img {
    display: grid;
    height: 60px;
}


/* //////// aqui vamos a colocar los estilosl //// */

.trio-tiitle h3 {
    font-weight: 900 !important;
    color: #034e8c !important;
    font-size: 12px;
    padding-left: 6px;
margin-left: 11px;
}

.trio {
    grid-template-columns: 40% 40% 20% !important;
    display: grid;
    align-items: center;
    column-gap: 9px;

}


.duo-elemental {
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 2%;
    justify-content: center;
    position: relative;
}


.trio-dos {
    grid-template-columns: 30% 30% 30% !important;
    display: grid;
    align-items: center;
    column-gap: 9px;

}




.input-element {
    width: 100%;
    grid-template-columns: 100%;
    display: grid;
    padding: 10px 0px 10px 0px ;
    text-align: left;
    position: relative;
    
}

.input-element-transparent {
    
    width: 100%;
    grid-template-columns: 100%;
    display: grid;
    padding: 10px 0px 10px 0px ;
    text-align: left;
    position: relative !important;
    
}


.input-element-white {
    width: 100%;
    grid-template-columns: 100%;
    display: grid;
    padding: 10px 0px 10px 0px ;
    text-align: left;
    position: relative !important;
    
}


.input-element-white label {
    
    font-size: 17px !important;
    color: #706f70 !important;
    font-weight: 400 !important;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.075);
    padding-left: 6px;
    margin-left: 11px;
    display: grid;
    position: relative;
    top:-1px;
    background: rgba(255, 255, 255, 0);
    width: 100px;
    text-align: left;
    border-style:none;
    
    
}

.input-element-white input  {
    border:1px solid #0037641c !important;
    font-size: 17px !important;
    position:relative;
    border-style:none;
    padding: 15px;
    transition: all 150ms ease;
    border-radius:2px;
    box-shadow: 0px -1px 2px #0037641c, 0px 5px 4px #0037642a ;
    background: rgba(255, 255, 255, 0);
    color: #000c14 !important;
    
}




.input-element-white select  {
    font-size: 17px !important;
    border-style:none;
    padding: 15px;
    transition: all 150ms ease;
    border-radius:2px;
    box-shadow: 0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    background: rgba(255, 255, 255, 0);
    color: #000c14 !important;
    
}



.input-element-transparent-btn {
    
    width: 100%;
    grid-template-columns: 100%;
    display: grid;
    padding: 10px 0px 10px 0px ;
    text-align: left;
    position: relative !important;
    
}



button , input , div {
    outline: none !important;
}


.input-element-transparent-btn button {
    border-style: none;
    background: #dfdfdf36;
    border-radius: 2px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    transition: all 200ms ease;
    width: 100%;
}



.input-element-transparent-btn button:hover {
    transition: all 200ms ease;
    background: #034e8c;
}




.input-element-transparent label {
    
    font-size: 0.7rem !important;
    color: #fff !important;
    font-weight: 400 !important;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.075);
    padding-left: 6px;
    margin-left: 11px;
    display: grid;
    position: relative;
    top:6px;
    background: rgba(255, 255, 255, 0);
    width: 100px;
    text-align: left;
    border-style:none;
    
    
}

.input-element-transparent select  {
    border:1px solid #04508c !important;
    border-style:none;
    padding: 10px;
    transition: all 150ms ease;
    border-radius:2px;
    box-shadow: 0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    background: rgba(255, 255, 255, 0);
    color: #000c14 !important;
    
}


.input-element-transparent  input {
    border-left:1px solid #c7c7c76b !important;
    border-right:1px solid #c7c7c76b !important;
    border-bottom:1px solid #c7c7c76b !important;
    border-top:1px solid transparent !important;
    border-style:none;
    padding: 10px;
    transition: all 150ms ease;
    border-radius:2px;
    box-shadow: 0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    background: rgba(255, 255, 255, 0) !important;
    color: #fff !important;
    
    
}

.input-element  input:focus {
    
    background: transparent !important;    
    transition: all 150ms ease;
    border-style:none;
}


.color-atlas-white {
    color: #fff;
}

 .input-element-transparent::before {
    display: grid;
    content:'' !important;
    bottom:17px !important;
    left:25% !important;
    height: 10px !important;
    width: 70% !important;
    border-top:1px solid rgba(255, 255, 255, 0.519) !important; 
    
    
    
    border-style:none;
    padding: 10px;
    
    border-radius:2px;
    position: absolute;
    color: #fff !important;
    z-index: -1;
    
}






.input-element button {
    border-style: none;
    background: #003764;
    border-radius: 2px;
    padding: 15px;
    color: #fff;
    cursor: pointer;
    font-size: 19px ;
    transition: all 200ms ease;
}



.input-element button:hover {
    transition: all 200ms ease;
    background: #034e8c;
}




.input-element label {
    
    font-size: 0.7rem !important;
    color: #706f70 !important;
    font-weight: 400 !important;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.075);
    padding-left: 6px;
    margin-left: 11px;
    display: grid;
    position: relative;
    top:6px;
    background: #fff;
    width: 100px;
    text-align: left;
    border-style:none;
    
    
}


.input-size-3 {
    width: 210px !important;
    top: 11px !important;
    
    
}

.input-size-1 {
    width: 40px !important;
}


.input-size-4 {
    width: 145px !important;
}


.input-size-5 {
    width: 175px !important;
}

.input-size-6 {
    width: 215px !important;
}

.input-size-7 {
    width: 100% !important;
}

.input-size-8 {
    width: 350px !important;
}

.textarea-size {
    height: 140px !important;
    display: grid;
}


.input-element select {
    color: #04508c !important;
    cursor: pointer !important;
    
}

.d-none {
    display: none !important;
}

.input-element select {
    border:1px solid rgb(231, 231, 231) !important;
    border-style:none;
    padding: 10px !important;
    height: 50px;
    transition: all 150ms ease;
    border-radius:2px;
    box-shadow: 0px -1px 2px #0037641c, 0px 5px 4px #0037642a !important;
    -webkit-box-shadow:0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    -moz-box-shadow:0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    background: #fff;
    color: #000c14 !important;   
}




.input-element  input {
    border:1px solid rgb(231, 231, 231) !important;
    border-style:none;
    padding: 10px;
    transition: all 150ms ease;
    border-radius:2px;
    box-shadow: 0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    -webkit-box-shadow:0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    -moz-box-shadow:0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    background: #fff;
    color: #000c14 !important;   
}

.input-element  input:hover {
    
    border:1px solid  #4cc1d3 !important;
    transition: all 150ms ease;
    border-style:none;
}

.input-element  input:focus {
    
    
    transition: all 150ms ease;
    border-style:none;
}


.text-center {
    text-align: center;
}

.Reserved{
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
    align-items: center;
    border-top:1px solid #dbdbdb67;
    border-bottom:1px solid #fff;
    padding: 1px 0px 1px 0px;
    background: rgb(255, 255, 255);
    
    z-index: 999999;
    
    
    position: fixed;
    bottom:0px;
    right:0px;
    width:100%;
}

.Reserved-2{
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
    align-items: center;
    border-top:1px solid #dbdbdb67;
    border-bottom:1px solid  rgba(255, 255, 255, 0.042);
    padding: 12px 0px 12px 0px;
    background: rgba(255, 255, 255, 0.042);
    color: #fff;
    z-index: 999999;
    text-shadow: 0px 0px 1px #fff;
    font-size: 19px !important;
    
    position: fixed;
    bottom:0px;
    right:0px;
    width:100%;
}


.Location-2{
    display: grid;
    position: fixed;
    bottom:6%;
    width: 100%;
    grid-template-columns: 20% 20% 20% 20% 20%;
    white-space: nowrap;
    justify-content: center;
    letter-spacing: 2px;
    text-align: center;

}

.Location-2 p {
    white-space: nowrap !important;
    text-transform: uppercase;
    font-size: 11px;
    
}

.elemento-deg-dos {
    display: grid;
    position: absolute;
    top:-10px;
    right: 0%;
    height: 15px;
    width: 15px;
    
    
    border:1px solid rgb(161, 161, 161);
    animation: joelgira 4000ms ease 0ms infinite alternate;
    z-index: -1;
    
}

.elemento-deg {
    display: grid;
    position: absolute;
    top:-10px;
    left: 0%;
    height: 15px;
    width: 15px;
    border-radius: 1px;
    border:1px solid rgb(161, 161, 161);
    animation: joelgira-left 4000ms ease 0ms infinite alternate;
    z-index: -1;
    
}

@keyframes joelgira-left {
    from {
        left: 0%;
        opacity: 0;
        transform: rotate(0deg);
    }
    to {
        left: 80%;
        opacity: 1;
        transform: rotate(720deg);
        border:1px solid #003764;
    }
}




@keyframes joelgira {
    from {
        right: 0%;
        opacity: 0;
        transform: rotate(720deg);
        
    }
    to {
        right: 80%;
        opacity: 1;
        transform: rotate(0deg);
        
    }
}


.Reserved-2 img{
    height: 30px;
}


.Reserved-2 p{
    font-size: 10px;
}

.Reserved-2 div{
    display: grid;
    align-items: center;
    justify-content: center;
}


.Reserved img { 

    height: 30px;
}

.Reserved p { 

    font-size: 10px;
}

.Reserved div { 
    display: grid;
    align-items: center;
    justify-content: center;
}


/* ////  aqui vamos a colocar la particula ////  */


#tsparticles {
    display: grid;
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 30%;
    filter: hue-rotate( 310deg) saturate(100%);
    animation : hueblue 5000ms ease 0ms infinite alternate;

    
} 


@keyframes hueblue {
    from {
        filter: hue-rotate( 310deg) saturate(100%);
    }
    to {
        filter: hue-rotate( 320deg) saturate(30%);
    }
}


.bg-particle-media::after{
    height: 100%;
    width: 100%;
    position: absolute;
    content:'';
    top: 0px;
    left: 0px;
    background: linear-gradient(to right top, #e2e1e1d4,rgb(255, 255, 255), rgba(255, 255, 255, 0.774), #ffffffc3, #e2e1e1d4);
    /* background: linear-gradient(to left top, #e2e1e100,#fff, rgba(255, 255, 255, 0.87), #fffffff0); */
    
    z-index: 0;
}

.bg-particle-media{
    display: grid;
    z-index: 0;
}



@media screen and (min-width: 993px) and (max-width:8000px) {
    .show-responsive {
        display: none !important;
    }
}



@media screen and (min-width:0px) and (max-width:992px){

    .opacity-reaction::before {
        content:'';
        height: 100%;
        width:100%;
        background: linear-gradient( to top , rgba(0, 0, 0, 0), #fff, #fff);
        z-index: 1;

    }

    .opacity-reaction{
        display: none !important;
    }

    .location {
        display:none !important;
    }

    .Logo-2x img {
        display: grid;
        margin-top: 10px;
        height: 48px !important;
        border: 1px solid rgba(255, 255, 255, 0.205);
        padding: 20px;
        margin: 0 auto;
      }

      .input-element-transparent input {
        font-size: 16px;
        padding: 10px;
      }

      .input-element-transparent label {
        font-size: 19px !important;
        top:13px;
        
      }

      .fix-height {
        height: 570px !important;
      }

      .hijo-hr {
        
        right: 35%  !important;
        top: -14px !important;
        
        
      }

      .input-element-transparent-btn button {
        font-size: 17px;
        padding: 15px;
      }

      .footer-special-tranparent { 
        font-size: 17px !important;
        padding: 15px !important;
        height: auto !important;
      }

      

      .input-element-transparent::before{
        bottom:23px !important;
        left: 53% !important;
        width: 40% !important;
      }

      .bg-style::after {
        background: linear-gradient(to top, #0b0e11e8 ,#031727, #04518c4f) !important ;
        
      }

      .duo-elemental {
        display: grid;
        grid-template-columns: 100%;
        column-gap: 0%;
        justify-content: center;
        position: relative;
      }

      .hide-responsive {
        display: none!important;
      }


      .form-register div {
        width:100% !important;
      }

      .show-responsive {
        display: grid;
      }


      .form-register .Logo {
        margin: 7vh 0px 6vh 0px;
      }


      .form-register {
        display: grid;
        
        
        border-radius: 3px;
        height: 570px !important;
        align-items: center;
        width: 330px;
        padding: 2px;
        z-index: 25;
        position: relative;
        animation: entrada-2 600ms ease-in-out 0ms 1 normal;    
    }

    .jimf-register-container {
        display: grid;
        justify-content: center;
        
    }
    


    .form-login {
        padding: 2px;
        width: 330px;
        font-size: 24px;

    }

    .form-login .Logo {
        margin: 7vh 0px 13vh 0px;
    }


    .bg-particle-media{
        display: grid;
        z-index: 0;
    }

    .jimf-col-2 {
        display: grid;
        grid-template-columns: 100%;
        position: relative;
        
    }
    
}

.bg-particle-media #tsparticles {
    height:99% !important;
    opacity: 0.9;
}

.bg-particle-media-black #tsparticles {
    height:99% !important;
    opacity: 0.9;
}




.Logo-2x {
    display: grid;
    justify-content: center;
}

.Logo-2x img {
    display: grid;
    margin-top:10px;
    height: 120px;
    border: 1px solid rgba(255, 255, 255, 0.205);
    padding: 20px;
}


.location {
    display: grid;
    grid-template-columns: 40% 60%;
    width: 100%;
    font-size: 10px;
    text-align: center;
    align-items: center;
    color: #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.205);
    margin-top: 10px ;
    background:linear-gradient( to bottom, #0000,#2a5e9652);
    box-shadow: 0px 0px 15px rgb(7, 61, 97);
}

.location-box-2 {
    border-left:1px solid rgba(255, 255, 255, 0.205);
    
    text-align: left;
    padding: 9px;
    line-height: 17px;
    font-size: 18px;
    font-weight: 800;
    text-shadow: 2px 1px 1px rgb(2, 19, 34);


}

.location-box-2 small {
    font-weight: 300;
}


.location-box-1 {
    
    text-align: right;
    padding-right: 12px;
    line-height: 10px;
    text-shadow: 2px 1px 1px rgba(2, 19, 34, 0.702);
    

}

/* //////////////////////// vamos a hacer la ventana de iniciar  ///// */




.jimf-col-1 {
    display: grid;
    grid-template-columns: 100%;
    position: relative;
    
} 


.bg-particle-media-black::after{
    height: 100%;
    width: 100%;
    position: absolute;
    content:'';
    top: 0px;
    left: 0px;
    /* background:linear-gradient(to top left ,rgba(26, 57, 115, 0.46) ,rgba(0, 0, 0, 0), rgba(7, 194, 241, 0.2)); */
    /* background: linear-gradient(to bottom ,rgba(11, 114, 182, 0.515),rgba(26, 57, 115, 0.909), rgba(11, 114, 182, 0.515) , rgba(0, 25, 83, 0.983)); */
    
    z-index: 0;
}

.bg-particle-media-black{
    display: grid;
    z-index: 0;
}

.none-shadow {
    box-shadow: none !important;
}


/* /////// login create acc //// */

.btn-float-password {
    display: grid;
    position:absolute !important;
    height: 30px !important;
    background: rgba(255, 255, 255, 0.192);
    align-items: center !important;
    
    
    color: rgb(255, 255, 255) !important;
    
    text-align: center;
    width:100% !important;
    top:-27px !important;
    right:0px;
    font-size:12px ;
    text-transform: uppercase;
    letter-spacing: 3px !important;
    font-weight: 900;
    text-shadow: 0px 0px 4px #fff !important;
    animation : hi 400ms ease 0ms 1 alternate;
    width: 100%;
    text-align: center;
    justify-content: center;

}

.btn-float-password span {
    display: grid;
    border-bottom: 1px dashed #034e8c2b;
    width: 100%;
    padding-bottom: 4px ;
    position: relative;
}

.btn-float-password span::after {
    position: absolute;
    content: '';
    display: grid;
    background: #e0e0e0;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    left:47%;
    bottom:8px;
    z-index: -1;
     animation : joemabig 1400ms ease 0ms infinite alternate;
}
 

@keyframes joemabig {
    from {
        transform: scale(0.2);
        opacity: 1;
    }
    to {
        transform: scale(10.0);
        opacity: 0;
    }
}

/* 
.btn-float-password span::before {
    position: absolute;
    content: '';
    display: grid;
    background: #034e8c;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    left:47%;
    bottom:8px;
    z-index: -1;
     animation : joemabigrev 1300ms ease 0ms infinite reverse;
} */
 

@keyframes joemabigrev {
    from {
        transform: scale(0.2);
        opacity: 1;
    }
    to {
        transform: scale(5.0);
        opacity: 0;
    }
}





input[autocomplete]:focus {
    outline: none;
    background: #ffffff !important;
    border:1px solid #000;
    transition: all 500ms ease;
}

input:-webkit-autofill {
    background:#ffffff1a !important;
}




@keyframes right-move {
    from {
        left:0%;
        
        
    }
    to {
        left:100%;
        
    }
}

    
 


@keyframes hi {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
} 


.fix-height {
    height:310px;
}


[data-tooltip-light] {
	position: relative;
    
    transition: all 500ms ease;
    
    height: 100%;
    
    
    cursor: pointer;
    letter-spacing: 0px;
    overflow:visible;
}
[data-tooltip-light]::before {
    transition: all 500ms ease;
	all: initial;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
    font-weight: 600;
	display: inline-block;
	border-radius: 2px;
	padding: 4px;
	background: #ffffff;
    box-shadow: -1px 1px 5px rgba(1, 12, 62, 0.194),2px -2px 5px rgba(1, 12, 62, 0.038);
    
    
	content: attr(data-tooltip-light);
	color: #014f83;
	position: absolute;
	top: -15px;
	
	right:-7px;
	
	transition: all 100ms ease;
	text-align: right;
	font-size: 11px;
    z-index: 99999; 
    cursor: pointer;
    overflow:visible ;
} 


[data-tooltip-light]:hover::before {
    background: #f7f7f7 !important;
    transition: all 100ms ease;
    
}



.footer-special button {
    display: grid;
    text-align: center;
    width: 100%;
    color: #003764;
    font-size: 15px;   
    transition: all 200ms ease;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.644);
    /* box-shadow:0px -1px 2px #0037641c, 0px 5px 4px #0037642a; */
    border-style: none;
    padding: 15px;
    align-items: center;
    border-top:1px solid rgb(218, 218, 218);
    border-left:1px solid rgb(218, 218, 218);
    border-right:1px solid rgb(218, 218, 218);
    border-bottom:1px solid rgb(218, 218, 218);
    
} 

.footer-special-tranparent {
    display: grid;
    text-align: center;
    width: 100%;
    color: #ffffff;
    font-size: 15px;   
    transition: all 200ms ease;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.076);
    /* box-shadow:0px -1px 2px #0037641c, 0px 5px 4px #0037642a; */
    border-style: none;
    height: 33px;
    align-items: center;
    border-top:1px solid rgb(218, 218, 218);
    border-left:1px solid rgb(218, 218, 218);
    border-right:1px solid rgb(218, 218, 218);
    border-bottom:1px solid rgb(218, 218, 218);
    
} 
.footer-special-tranparent:hover {
    display: grid;
    text-align: center;
    width: 100%;
    color: #ffffff;   
    transition: all 200ms ease;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.165);
    text-shadow: 0px 0px 4px #fff;
    box-shadow:0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    
    
} 


.shadow-x {
    text-shadow:-2px -1px 0px #d2d2d231;
    

}

.footer-special:hover {
    display: grid;
    text-align: center;
    width: 100%;
    color: #034e8c;
    font-size: 10px;   
    transition: all 200ms ease;
    cursor: pointer;
    background: rgb(255, 255, 255);
    box-shadow:0px -1px 2px #0037641c, 0px 5px 4px #0037642a;
    
    
} 



.bar-flag-all {
    display: grid;
    grid-template-columns: 7% 10% 7%;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top:0%;
    right:0px;
    z-index: 99999;
    opacity: 1;
}

.lil-text {
    font-size:8px !important;
}

@media screen and (min-width:0px) and (max-width:992px) {
    

    .bar-flag-all {
        display: grid;
        opacity: 0;
    }
}


.Active-lang {
    
    border-bottom: 1px solid transparent !important;
    
    border-left: 1px solid rgb(0, 132, 255) !important;
    border-top: 1px solid transparent !important;
    border-right: 1px solid rgb(0, 132, 255) !important;
}


.bar-flag-all button {
    
    outline: none !important;
    color: #fff !important;
    cursor:pointer !important;
    background: rgba(0, 0, 0, 0.541);
    border: 1px solid transparent ;
    padding: 4px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 3px;
    
}

.bar-flag-all button:hover {
    background:  #fff;

    background:#003764 !important;
    transition: all 200ms ease; 
}


.color-atlas {
    color: #003764;
}



.green {
    color: rgb(0, 193, 93);
    animation: zoom-jimf 90ms ease 0ms 1 normal;
    
}


.red {
    color: rgb(180, 21, 21);
    font-weight: 500;
    animation: zoom-jimf 90ms ease 0ms 1 normal;
}



@keyframes zoom-jimf {
    0% {
        transform: scale(4.0);
        opacity: 0;
        transition: all 150ms ease;
        filter: blur(30px);

    }
    100% {
        opacity: 1;
        transform: scale(1.0);
        filter: blur(0px);
        
        transition: all 150ms ease;
    }
}



.loader-atlas {
    align-items: center;
    justify-content: center;
    background: #fff!important;
    
    animation: bg-flash 460ms ease 0ms infinite alternate !important;
    


    
}

@keyframes bg-flash {
    0% {
        
        
        background: rgba(231, 231, 231, 0.15);
        transition: all 150ms ease;
        

    }
    100% {
        background: rgba(255, 255, 255, 0.134);
        transition: all 150ms ease;
    } 
}


.loader-atlas img {
    height: 90px;
    animation: zoom-jimf 360ms ease 0ms 1 normal;
}

.loader-atlas p {
    
    animation: opacity-loop 580ms ease 0ms infinite alternate;
    color:#003764;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 400;
}

.loader-atlas span {
    display: grid;
    background: rgba(214, 238, 255, 0.227);
    font-size: 4px;
    margin: 10px 0px;
    box-shadow: 2px 1px 2px rgb(214, 230, 250);
    position: relative;
    height: 4px;
    border: 1px solid #00345f31;
    border-radius: 2px;
    
}

.loader-atlas span::after {
    position: absolute;
    width: 10%;
    height: 100%;
    top:0;
    content: "";
    display: grid;
    background: rgba(18, 240, 147, 0.942);
    font-size: 4px;
    animation: bar-load 2280ms ease 0ms infinite normal;
}

@keyframes bar-load {
    0% {
        
        width: 0%;
        opacity: 0.10;

    }
    100% {
        width: 100%;
        opacity: 0.60;
    }
}

@keyframes opacity-loop {
    0% {
        
        opacity: 0;
        transition: all 150ms ease;
        filter: blur(1px);

    }
    100% {
        opacity: 1.00;
        filter: blur(0px);
        transition: all 150ms ease;
    }
}

.lista-ben {
    display: grid;
    height: 100px;
    background: #fff;
    width: 100%;
    
    position: relative;
    
    align-items: center;
    
}
.lista-ben p {
    margin:0px;
}

.lista-ben small {
    color: #003764;
    font-size: 13px;
    margin:0px;
}

.footer-alert {
    width: 100%;
    margin: 0px !important;
}
.grid-100 {
    grid-template-columns: 100% !important;

}

.ben-list {
    display: grid;
    width: 100%;
    grid-template-columns: 100%;
}

.fila-ben-head {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    font-size: 13px;
    width: 100%;
    padding:20px 20px;
    border-radius: 2px;
    background: linear-gradient(to right, #04508c, #002039);
    color: #fff;
    box-shadow: 0 1px 6px rgba(32,33,36,.28);
    
    text-align: center;
}

.fila-ben-body {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    font-size: 10px;
    width: 100%;
    padding:15px 20px;
    text-align: center;
    margin: 4px 0px;
    background: rgb(243, 243, 243);
    color: #035a79;
    cursor: pointer;
    box-shadow: 0 1px 6px rgba(32,33,36,.28);
    border-left: 1px solid #1277f7;
    font-weight: 800;
}

.fila-ben-body:nth-child(even) {
    background: #e9e9e9;
    color: #035a79;
}

.fila-ben-body:nth-child(even):hover {
    color: #035a79;
    background: #1275f761;
}

.fila-ben-body:hover {
    color: #035a79;
    background: #1275f761;
}




/* ///////////////////////////////// aqui voy a colocar el modal de beneficiario /////////// */
.Modal-info-ben {
    display: grid;
    height:  360px;
    background: rgb(255, 255, 255);
    width: 100%;
    position: absolute;
    z-index: 8888;
    border-radius: 2px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.046) !important;
    
    text-align: center;
    align-items: start;
}




.Modal-info-ben h3 {
    border-top: 1px solid #0037643a;
    border-bottom: 1px solid #0037643a;
    align-items: center;
    margin: 0px 20px;
    color: #003764;
    

}

.Modal-info-ben .header {
    display: grid;
    grid-template-columns: 30%;
    justify-content: right;
    background: #034e8c;
    border-radius: 3px;
}
.Modal-info-ben .header button {
    margin: 5px 0px;
}


.loader-seudo {
    display: grid;
    position: fixed;
    z-index: 99999000;
}

.blurmanload {
    display: grid;
    filter: blur(30px);
    
}


/* ///////////////////loader en negro /////// */

.loader-atlas-black {
    align-items: center;
    justify-content: center;
    display: grid;
    animation: bg-flash-black 860ms ease 0ms infinite alternate !important;
    justify-content: center;
    


    
}

@keyframes bg-flash-black {
    0% {
        
        
        background:  rgba(0, 9, 28, 0.594);
        transition: all 150ms ease;
        

    }
    100% {
        background: rgba(0, 9, 28, 0.894);
        transition: all 150ms ease;
    } 
}


.loader-atlas-black img {
    height: 120px;
    animation: zoom-jimf 360ms ease 0ms 1 normal;
    margin: 0px auto;
}

.loader-atlas-black p {
    
    
    color:#fff;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 400;
}

.loader-atlas-black div {
    display: grid;
}

.loader-atlas-black span {
    border-right: 4px solid #6510af;
    border-left: 4px solid #6510af;
    border-top: 4px solid #6510af;
    border-bottom: 4px solid #f2e2ffb9 !important;
    position: relative;

    height: 30px;
    width: 30px;
    margin: 0px auto;
    border-radius: 100px;
    
    animation: rotate-load-atlas 1200ms linear 0ms infinite normal;

}

.App {
    background:linear-gradient(to top, rgba(26, 26, 26, 0.5), rgb(0, 0, 0)),
     /* url(/src/A-Bienes-Raices/imagenes/background/bghome3.jpg) */
     /* url(https://a0.muscache.com/im/pictures/485b26d8-c9de-4025-9607-5effba8945a2.jpg) */
        /* url(https://a0.muscache.com/im/pictures/monet/Luxury-570973165437649140/original/704468c1-47cd-44e0-9d1a-3ea3db51a2e6) */
     ;
    background-size: 200%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    animation: bg-wow 50000ms ease 0ms normal infinite;
}

@keyframes bg-wow {
    from {
        background-position: 0% 50%;
        background-size: 150%;
  
    }
    to {
        background-position:100% 50%;
        background-size: 120%;
    
    }
}

@keyframes rotate-load-atlas {
    from {
        transform: rotate(0deg);
        opacity: 1.00;
    }
    to {
        transform: rotate(360deg);
        opacity: 0.60;
    }
}

/* .loader-atlas-black span {
    display: grid;
    background: rgba(186, 215, 236, 0.144);
    font-size: 4px;
    margin: 10px 0px;
    
    position: relative;
    height: 4px;
    border: 1px solid #00345f31;
    border-radius: 2px;
    
}*/

.loader-atlas-black span::after {
    position: absolute;
    
    top:-4px;
    left:-4px;
    content: "";
    display: grid;
    height: 30px;
    width: 30px;
    border-right: 4px solid  transparent !important;
    border-left: 4px solid   transparent !important;
    border-top: 4px solid #ca93fa !important;
    border-bottom: 4px solid transparent !important;
    margin: 0px auto;
    border-radius: 100px;
    z-index: -2;
    animation: rotate-load-atlas 800ms linear 0ms infinite reverse;
} 


.joema-max {
    display: grid;
    grid-template-columns:95% !important;
}

@media screen and (min-width:0 ) and (max-width:992px) {

    
.container-steper-hijo span:nth-child(1) {
    background: rgb(0, 21, 49) !important;
}

.container-steper-hijo span:nth-child(1)::after {
    position: absolute;
    height: 50px !important;
    background: #b3ffdf;
    color: #1c1570 !important;
    border:1px solid #b3ffdf !important;
    width: 100% !important;
    display: grid;
    text-align: center;
    align-items: center !important;
    padding: 5px;
    top: -40px !important;
    left:0px !important;
    content: attr(att);
    z-index: 2;
    font-size: 14px !important;
    line-height: 13px;
    border-radius: 40px !important ;
}

.container-steper-hijo span:nth-child(2)::after {
    position: absolute;
    height: 50px !important;
    background: #0365ff8f;
    color: #fff !important;
    border:1px solid #b3ffdf !important;
    width: 100% !important;
    display: grid;
    text-align: center;
    align-items: center !important;
    padding: 5px;
    top: -40px !important;
    left:0px !important;
    content: attr(att);
    z-index: 2;
    font-size: 14px !important;
    line-height: 13px;
    border-radius: 40px !important ;
    text-shadow: none !important;
}





.container-steper-hijo span:nth-child(3)::after {
    position: absolute;
    height: 50px !important;
    background: #001b468f;
    color: #fff !important;
    border:1px solid rgb(252, 164, 0) !important;
    width: 100% !important;
    display: grid;
    text-align: center;
    align-items: center !important;
    padding: 5px;
    top: -40px !important;
    left:0px !important;
    content: attr(att);
    z-index: 2;
    font-size: 14px !important;
    line-height: 13px;
    border-radius: 40px !important ;
    text-shadow: none !important;
}

.container-steper-hijo span:nth-child(4)::after {
    position: absolute;
    height: 50px !important;
    background: #001b468f;
    color: #fff !important;
    border:1px solid rgb(252, 164, 0) !important;
    width: 100% !important;
    display: grid;
    text-align: center;
    align-items: center !important;
    padding: 5px ;
    top: -52px !important;
    left:-2px !important;
    content: attr(att);
    z-index: 2;
    font-size: 14px !important;
    line-height: 13px;
    border-radius: 40px !important ;
    text-shadow: none !important;
}


.container-steper-hijo span:nth-child(5)::after {
    position: absolute;
    height: 50px !important;
    background: #001b468f;
    color: #fff !important;
    border:1px solid rgb(252, 164, 0) !important;
    width: 100% !important;
    display: grid;
    text-align: center !important;
    align-items: center !important;
    padding: 5px;
    top: -52px !important;
    left:-2px !important;
    content: attr(att);
    z-index: 2;
    font-size: 14px !important;
    line-height: 13px;
    border-radius: 40px !important ;
    text-shadow: none !important;
}


    .container-information div {
        height: 869px !important;
    }

    .container-steper span {
        font-size: 24px !important;
        padding: 10px 20px;
        line-height: 22px !important;
        height: 53px !important;
        
    }

    .container-steper-hijo {
        display: grid;
        grid-template-rows: 19% 19% 19% 19% 19% !important;
        grid-auto-flow:row !important;
        grid-template-columns: none !important;
        
        height: 900px !important;
    }


    .container-steper-hijo span:nth-child(1)::before {
        content: '';
        position: absolute;
        height: 60%;
        background: rgb(242, 210, 67);
        top:110% !important;
        width: 1px;
        display: grid;
        text-align: center;
        left:50%;
        z-index: -1 !important;
    } 


    .container-steper-hijo span:nth-child(2)::before {
        content: '';
        position: absolute;
        height: 55% !important;
        background: rgb(242, 210, 67);
        top:110% !important;
        width: 1px;
        display: grid;
        text-align: center;
        left:50%;
        z-index: -1 !important;
    }

    .container-steper-hijo span:nth-child(3)::before {
        content: '';
        position: absolute;
        height: 40% !important;
        background: rgb(242, 210, 67);
        top:110% !important;
        width: 1px;
        display: grid;
        text-align: center;
        left:50%;
        z-index: -1 !important;
    }

    .container-steper-hijo span:nth-child(4)::before {
        content: '';
        position: absolute;
        height: 45% !important;
        background: rgb(242, 210, 67);
        top:110% !important;
        width: 1px;
        display: grid;
        text-align: center;
        left:50%;
        z-index: -1 !important;
    }

    .container-steper-hijo span:nth-child(5)::before {
        display: none !important;
    }
    
    .container-steper-hijo span:nth-child(1) {
        background: linear-gradient(to right, rgb(13, 25, 55), rgb(0, 33, 82), rgb(0, 23, 67), rgb(13, 57, 147)) !important;
    }

    .container-steper-hijo span {
        background: linear-gradient(to right, rgb(13, 25, 55), rgb(0, 33, 82), rgb(0, 23, 67), rgb(13, 57, 147)) !important;
    }

    


    
}







.container-steper {
    display: grid;
    
    height: 220px;
    
}

.container-steper-hijo span {
    position: relative !important;
    overflow: visible;
    z-index:0;
    box-shadow: 2px 2px 3px #000, -1px -1px 12px rgb(2, 15, 74);
}

.container-steper-hijo {
    display: grid;
    grid-template-columns: 19% 19% 19% 19% 19%;
    column-gap:6px ;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: visible;
    width: 90%;
    margin: auto;
    
}

@media screen and (min-width:993px) and (max-width:8000px) {

    

    .icon-joema {
    
        bottom:0px !important;
        position: relative !important;
        right: 0 !important;
        opacity: 1 !important;
        filter: saturate(100%) !important ;
        transition: all 200ms ease;
        z-index: 0;
        
        margin-left: auto  !important;
        margin-right: auto  !important;
        height: 100px  !important;
    }

    .container-steper-hijo span:nth-child(1) {
        background: rgb(0, 21, 49) !important;
    
    }
    
    .container-steper-hijo span:nth-child(2) {
        background: rgb(0, 30, 70) !important;
    }
    
    .container-steper-hijo span:nth-child(3) {
        background: rgb(0, 47, 109) !important;
    }
    
    .container-steper-hijo span:nth-child(4) {
        background: rgb(1, 78, 179) !important;
        
    }
    
    .container-steper-hijo span:nth-child(5) {
        background: rgb(6, 112, 250) !important;
        
        
    }

}



.container-steper-hijo span:nth-child(1)::after {
    position: absolute;
    height: 20px;
    width: 180%;
    display: grid;
    text-align: center;
    padding: 5px;
    top: -65px;
    left:-27px;
    content: attr(att);
    z-index: 2;
    font-size: 11px;
    line-height: 12px;
    color: #bbd3ff;
}

.container-steper-hijo span:nth-child(2)::after {
    position: absolute;
    height: 20px;
    width:210%;
    display: grid;
    text-align: center;
    padding: 5px;
    bottom: -66px;
    left:-60px;
    content: attr(att);
    z-index: 2;
    font-size: 11px;
    line-height: 13px;
    text-shadow: 1px 1px 4px #000;
    color: #bbd3ff;
}





.container-steper-hijo span:nth-child(3)::after {
    position: absolute;
    height: 20px;
    width: 180%;
    display: grid;
    text-align: center;
    padding: 5px;
    top: -65px;
    left:-42px;
    content: attr(att);
    z-index: 2;
    font-size: 11px;
    color: #bbd3ff;
    line-height: 13px;
    text-shadow: 1px 1px 4px #000;
}

.container-steper-hijo span:nth-child(4)::after {
    position: absolute;
    height: 20px;
    width: 180%;
    display: grid;
    text-align: center;
    padding: 5px;
    bottom: -65px;
    left:-27px;
    content: attr(att);
    z-index: 2;
    font-size: 11px;
    color: #bbd3ff;
    line-height: 13px;
    text-shadow: 1px 1px 4px #000;
}


.container-steper-hijo span:nth-child(5)::after {
    position: absolute;
    height: 20px;
    width: 180%;
    display: grid;
    text-align: right;
    padding: 5px;
    top: -65px;
    left:-63px;
    content: attr(att);
    z-index: 2;
    font-size: 11px;
    color: #bbd3ff;
    line-height: 13px;
    text-shadow: 1px 1px 4px #000;
}



.container-steper-hijo span:nth-child(1)::before {
    content: '';
    position: absolute;
    height: 60%;
    background: rgb(242, 210, 67);
    top:-65%;
    width: 1px;
    display: grid;
    text-align: center;
    left:50%;
    z-index: -1 !important;
}

.container-steper-hijo span:nth-child(3)::before {
    content: '';
    position: absolute;
    height: 60%;
    background: rgb(242, 210, 67);
    top:-65%;
    width: 1px;
    display: grid;
    text-align: center;
    left:50%;
    z-index: -1 !important;
}

.container-steper-hijo span:nth-child(5)::before {
    content: '';
    position: absolute;
    height: 60%;
    background: rgb(242, 210, 67);
    top:-65%;
    width: 1px;
    display: grid;
    text-align: center;
    left:50%;
    z-index: -1 !important;
}

.container-steper-hijo span:nth-child(2)::before {
    content: '';
    position: absolute;
    height: 80%;
    width: 1px;
    display: grid;
    text-align: center;
    background: rgb(230, 242, 67);
    bottom:-90%;
    left:50%;
    z-index: -1 !important;
}

.container-steper-hijo span:nth-child(4)::before {
    content: '';
    position: absolute;
    height: 80%;
    width: 1px;
    display: grid;
    text-align: center;
    background: rgb(230, 242, 67);
    bottom:-90%;
    left:50%;
    z-index: -1 !important;
}




.container-steper-hijo span {
    height: 40px;
    background: rgb(12, 161, 215);
    border-radius: 2px;
    color: #fff;
    font-size: 11px;
    line-height: 10px;
    display: grid;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    text-align:center

}

.container-information {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: center;
    column-gap: 10px;
    height: 250px;
    
    position: relative;
    
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;

}

 

.container-information div {
    height: 200px;
    
} 
.cuenta-joema-box-bold {
    display: grid;
    height: 100px !important;
}

.cuenta-joema-box-bold p {
    font-size:13px !important;
    margin:0px !important;
    border-bottom:  1px solid rgba(162, 162, 162, 0.32) !important;
    font-weight: 900; 
}

.cuenta-joema-box {
    display: grid;
}

.cuenta-joema-box p {
    font-size:13px !important;
    margin:0px !important;
    border-bottom:  1px solid rgba(162, 162, 162, 0.32) !important;
}

/* ///// aqui vamos a hacer el estado y el grid ///// */

.box-action-joema{
    display: grid;
    height: 300px;
    background: #000;
    border:1px solid #b3ffdf !important;
    position: relative;
    background: linear-gradient(to top right, rgba(73, 123, 157, 0.655) , rgba(0, 8, 52, 0.76), rgb(8, 16, 33) );
    
}




.Main-controller-style img {
        height:100%;
        bottom: -50px;
        position: absolute;
        right: -30px;
        opacity: 0.30;
        filter: saturate(10%) ;
        transition: all 200ms ease;
        z-index: 0
}

.Main-controller-style:hover img {
    transition: all 200ms ease;
    filter: saturate(100%) ;
    opacity: 0.90;
    right: 0px;
} 

.icon-btn-atlas-joema{
    display: grid;
    position: absolute;
    height: 70px ;
    top:15px;
    right: 10px;
    z-index:-3;
    filter:saturate(100%) ;
    opacity: 0.10;
    transition: all 500ms ease;
}

.fix-joema-img {
    right: 20px !important;
}

.active-img-joema img {
    opacity: 0.40 !important;
    transition: all 300ms ease;
}


.Main-controller-style {
    display: grid;
    grid-template-columns: 100%;
    margin:0px;
    color: #bbd3ff;
    align-items: center;
    padding: 0px 10px;
    position: relative;
}

.Main-controller-style h3 {
    border-bottom:1px solid #c0c0c038;
    height: 30px;
    animation : rightmotion 700ms ease-in-out 3ms 1 normal;
}

.duo-controller {
    display: grid;
    grid-template-columns: 47% 47%;
    align-items: center;
    height: 200px;
    justify-content: center;
    
}

.elemento-duo {
    display: grid;
    text-align: center;
    
}

.main-header-name {
    display: grid;
    color: #fff;
    padding: 10px;
    grid-template-columns: 100%;
    align-items: center;
    height: 100px;
    
}

.main-header-name h2 {
    display: grid;
    font-weight: 800;
    letter-spacing: -1px;
    line-height: 22px;
    /* text-shadow: 3px -3px 0px #000; */
    /* color: linear-gradient(to right, #000, #000)  !important; */
    /* background: linear-gradient(rgb(31, 183, 158), rgb(9, 225, 135)); */
  margin: 0px;
  align-items: center !important;
  font-size: 19px;
  text-align: center;
  


}

.main-header-name span {
    font-size: 14px;
    letter-spacing: 1px;
    color: rgb(225, 225, 225);
    
    text-align: center !important;
    width: 100%;
    
    
    
    
}
.main-header-name span strong {
    padding: 0px 9px 0px 9px ;
}

.main-header-name span small {
    font-weight: 800;
}



/* ///// aqui vammos a colocar los estilos del logout el baton ///// */

.componente-login {
    display: grid;
    grid-template-columns: 70% 20% !important;
    width: 100% !important;
    
    justify-content: center;
    align-items: center;
    

}


.componente-hijo{
    display: grid;
    grid-template-columns: 50% 50% ;
    width: 100%;
    
    
    
    text-align: center;
}


.active-lang-btn {
    color: #fff !important;
    text-shadow: 0px 0px 4px #fff;
}

.componente-hijo-2 {
 display: grid;
 width: 90%;
 align-items: center;
 padding: 0px 10px 0px 10px;
}


.text-right {
    text-align: right !important;
}


.text-fris {
    width: 120px;
    
    display: grid;
    align-self: center;
    margin: 0px auto;
    

}

.cuento-auth {
    font-size:13px;
}




.electronic-container {
    border-top:1px solid rgba(212, 212, 212, 0.334);
    background: rgba(246, 246, 246, 0.176);
    padding: 2px 20px;
    margin:10px 0px;
}


.electronic-container p {
    margin:2px 0px;
    color: rgb(7, 40, 105);

}

.electronic-comun-atlas-container {
    position:relative;
    margin-top: 1px;
    padding: 0px 0px 10px 0px;   
}


.caja-boton-firmantes {
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    column-gap: 7px;
    border-bottom:1px solid rgb(225, 225, 225);
    padding:10px 30px;
    
}

.caja-boton-firmantes button {
    height: 80px;
    padding: 3px !important;
}


.descarga-pdf {
    color: #fff;
    text-decoration: none;
    text-align: center;
}

.pdf {
    padding: 10px;
    width:100%;
    color: #fff;
}

.cuatro-icon {
    display: grid;
    grid-template-columns: 47% 47%  !important;
    column-gap: 20px;
    border-bottom:1px solid rgb(192, 192, 192);
    border-top:1px solid rgb(192, 192, 192);
    padding:7px 0px;
    margin:7px 0px;
} 

.contacto-coorporate {
    display: grid;
    color: #fff;
    background: #022e52;
    padding: 7px 5px 7px 5px;
    border-radius: 2px ;
}


.contacto-coorporate  p {
    margin: 0px;
}


.box-contenedores {
    display: grid;
    background: linear-gradient( to left,#00092b,#032140);
}

.input-element-dark {
    margin: 0px auto  !important;
    height: 50px !important;
    border: none !important;
    width: 100% !important;
    padding:0px 0px 0px 0px !important;
}

.input-element-dark label {
    background: #031d5c;
    color: #fff !important;
    padding:0px 0px 0px 0px !important;
}
.input-element-dark input {
    background: transparent;
    box-shadow: none !important;
    
}

.joema-box-grid {
    display: grid;
    position: relative !important;
    margin:0 !important ;
    
    
}
.bar-tab-joema {
    height: 70px !important;
    display: grid;
    position: relative !important;
    width: 100%;
    margin:0px !important;
    grid-template-columns: 50% 50%  !important;
}


.footer-pax {
    color: #fff !important;
    background:linear-gradient(270deg,#13131305,#5e64ca5e) !important;
    padding: 8px 20px ;
    font-size: 12px ;
    font-weight: 400 !important;
    
}




@media screen and (min-width:0px) and (max-width:992px) {

    .fix-son-2 {
        display: grid;
        grid-template-columns: 100% !important;
        column-gap: 0px;
        width: 100%;
        justify-content: space-between;
    }

    .joema-box-grid {
        align-items: center;
    }

    .cuenta-joema-box-bold {
        height: 350px !important;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.301);
        

    }
    .joema-box-grid {
        grid-template-columns: 100%;
        width: 100%;
        height: 930px !important;
    }

    .icon-joema {
        bottom:0px !important;
        position: relative !important;
        right: 0 !important;
        opacity: 1 !important;
        filter: saturate(100%) !important ;
        transition: all 200ms ease;
        z-index: 0;
        margin-left: auto  !important;
        margin-right: auto  !important;
        height: 170px  !important;
    }
    .cuenta-joema-box {
        display: grid;
        height: 490px !important;
        padding: 15px;
        background: #031d5c !important;
        
        border: 1px solid rgba(255, 255, 255, 0.319);
        margin-left:4px !important;
        margin-right:4px !important;
    }

    .footer-pax {
        color: #fff !important;
        background: linear-gradient( to left, rgb(19, 19, 19), rgb(49, 49, 49)) !important;
        padding: 20px;
        font-size: 23px !important;
        font-weight: 400 !important;
        line-height: 29px !important;
    }


    .joema-box-grid div:nth-child(0){
        display: none !important;
    }
}

.active-btn-set {
    border-left:20px solid transparent !important;
    background: #b3ffdf !important;
    color:#031c3c !important;
    text-shadow: none !important;
}

.active-btn-set-2 {
    border-left:20px solid transparent !important;
    color: #b3ffdf !important;
    background: #031c3c !important;   
    text-shadow: none !important;
}

.lang-bg-es {
    background : linear-gradient(to right top, rgb(0, 21, 98),rgba(0, 21, 98, 0.713), rgba(26, 11, 11, 0), rgba(0, 0, 0, 0)), url(/src/imagenes/Flags/ES.jpg);
    filter: saturate(40%);
    border-radius: 3px;
    background-size: contain;
    background-repeat:no-repeat !important;
    width: 100% !important;
    padding: 0px !important;

    background-position: 50% 50%;
    text-align: center;
    
    border-radius: 0px;

}

.lang-bg-en {
    background : linear-gradient(to  right top, rgb(0, 21, 98),rgba(0, 21, 98, 0.879), rgba(26, 11, 11, 0), rgba(0, 0, 0, 0)), url(/src/imagenes/Flags/USA.jpg);
    filter: saturate(40%);
    border-radius: 3px;
    background-size: contain;
    background-repeat:no-repeat !important;
    width: 100% !important;
    padding: 0px !important;

    background-position: 50% 50%;
    text-align: center;
    
    border-radius: 0px;
}

.size-text-yeah {
    height: 40px !important;
    border-left: 1px solid #014f83;
    border-right: 1px solid #014f83;
    padding: 0px 10px 0px 10px;
    display: grid;
    background: linear-gradient( to bottom, rgba(0, 0, 0, 0) , rgb(0, 63, 157));
    align-items: center;
    color: #b3ffdf !important;

}

.fix-box-lang {
    justify-content: center;
    align-items: flex-end;
}


.box-icon-joema {
    display: grid;
    justify-content: center;
    align-items: center;
    
}




.icon-joema:hover {
transition: all 200ms ease;
filter: saturate(100%) !important;
opacity: 0.90 !important;
right: 0px !important;
} 

.acordeon {
    /* background : #000; */
    display: flex;
    flex-direction: column;
    position: relative;
    
    
}

.acordeon-body {
    background: #1d26a8;
    padding: 5px 10px 5px 10px !important;
    text-align: left;
}

.acordeon-header {
    display: grid;
    grid-template-columns: 50% 10%;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    margin-bottom: 2px;
    text-align: center;
    padding: 5px 10px 5px 1px !important;
    background: #00092b;
    

}
.acordeon-header h4 {
    margin: 0px !important;
}

.icon-rotate {
    background : #b3ffdf;
    width: 30px ;
    height: 30px ;
    border-radius: 100px !important;
    display: grid;
    justify-content: center;
    align-items: center;
    transition: all 100ms ease;
    cursor: pointer;
    color : #014f83;
}

.icon-rotate-active {
    color : #b3ffdf;
    transform: rotate(90deg);
    font-weight: 900 !important;
    border: 1px solid #014f83;
    background: #1d26a8;
    box-shadow: 0px 0px 4px #000;

}

.acordeon-header:hover .icon-rotate {
    transform: rotate(90deg);
    transition: all 200ms ease;
}

.acordeon {
    
    margin: 0px !important;
}


.acordeon section {
    border-radius: 2px;
}

    .container-acordeon {
        display: block !important; 
        height: 200px !important; 
        overflow-y: scroll !important; 
    }

   
    .burger-container {
        display: grid;
        position: absolute;
        width: 100%;
        top:0px;
        right:0;
        z-index: 9999999999;
        grid-template-columns: 100%;
        overflow-x: none !important;
    }
    .burger-container p {
        font-size: 19px !important;
    }
    .burger-container h2 {
        font-size: 22px !important;
    }
    .burger-container h3 {
        font-size: 19px !important;
        padding: 40px;
    }
    .burger-container button {
        font-size: 23px !important;   
    }
    .burger-container small {
        font-size: 14px !important;
    }
    .burger-container div {
        margin: 0px !important;
        padding: 0px !important;
    }
    .navbar-container-float {
        display: block;
        height: 60px;
        width:100%;
        position: relative;
    }
    .text-white {
        color: #fff !important;
    }
    .btn-transparent-atlas {
        display: grid;
    }


    .input-element label {
        font-size: 17px !important;
    }

    .input-element input {
        font-size: 17px !important;
    }

    .input-element select {
        font-size: 17px !important;
    }
  

    @media screen and (min-width:0px) and (max-width:992px) {

        .container-cuenta-100 {
            display: grid;
            grid-template-columns: 100% !important;
            
    
        }
    
        

        .joema-responsive-input label {
            font-size: 22px !important;
            width:60% !important;
            height: 27px !important;
            top: 31px !important;
            margin-left: 20px !important;
            
        }

        .joema-responsive-input input {
            font-size: 25px !important;
        }

        .joema-responsive-input {
            max-height: 115px !important
        }

        .btn-transparent-atlas {
            margin-bottom: 10px !important;
            margin-top: 10px !important;
            height: 80px;
        }


        /* // aqui vamos a colocar los estilos de los botones grandes ///// */
            .container-form-window {
             min-height: 590px !important;   
             padding-bottom:100px !important;
            }

        .input-element label {
            font-size: 19px !important;
            width:90% !important;
            height: 27px !important;
            top: 11px !important;
            margin-left: 20px !important;
            
        }
        .form-register h2 {
            font-size: 24px;
            margin-bottom:20px !important;
        }

        .Location-2 {
            display: grid;
            background: #fff;
            letter-spacing: 0px !important;
            grid-template-columns:  50% 50%  !important;
            width: 70% !important;
            
            text-align: left !important;
            height: 35px;
            top: 3%;
            left: 7%;
            z-index: 9999 !important;
            opacity: 0.00;
          }

          .Location-2 p:nth-child(3){
            display: none !important;
          } 

          



        .input-element input {
            font-size: 19px !important;
        }

        .input-element {
            max-height: 115px !important
        }
        
        .input-element {
                
                max-height: 55px;
            }

            /* // aqui vamos a colocar los estilos de los botones grandes ///// */


        .kitmenu button:hover {
            transform: none !important;
        }
        .kitmenu button {
            transform: none !important;
        }

        .BlueA {
            margin-bottom: 20px;
        }

        .kitmenu .header {
            grid-template-columns: 100% !important;
            row-gap: 30px !important;
            column-gap: 0px !important;
            margin-top: 10px !important;
            padding-left: 10px !important;
            padding-right: 10px !important;
            position: relative;
            align-items: start;
          }

        .kitmenu .body {
            grid-template-columns: 100% !important;
            row-gap: 30px !important;
            column-gap: 1px !important;
            margin-top: 10px;
            padding-left: 10px !important;
            padding-right: 10px !important;
            align-items: start;
            
        }

        .text-fris {
            width:100% !important;
        }

        .kitmenu button h3 {
            font-size: 20px !important;
            padding: 0px !important;
        }

        .text-kit-menu span {
            font-size: 16px !important;
        }

        .head h1 {
            font-size: 29px !important;
        }

        .text-kit-menu p {

            font-size: 26px !important;
            
        }

        .kitmenu {
            padding: 0 !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: 100% !important;
            
        }

        .joemaavisa {
            background: linear-gradient(to left, #1f3170, #5275f5) !important;
            height: 1600px !important;
            display: grid;
            align-items: start;
        }



        .container-information  {
            height: 900px;
            
        }

        .atlaslayerout .pilastra-2 {
            display: none !important;
        }

        .atlaslayerout .pilastra-3 {
            display: none !important;
        }

        .main-nav-option-btn-controller {
            display: none !important;
        }



        .navbar-responsive-option {
            display: grid;
             /* grid-template-columns: 22% 22% 22% 22% !important; */
            height: 500px !important;
            justify-content:center;
            
            
        }
        .navbar-responsive{
            display: block;
            height: 635px !important;
            background: linear-gradient(to bottom right, #3850a4, #000824) !important;
            grid-auto-flow:column !important;
            grid-template-rows:none !important;
            
        }

        .navbar-responsive-header {
            height: 120px !important;
            background: url(../imagenes/bgRound.svg),linear-gradient(to right, rgb(13, 25, 55), rgb(0, 33, 82), rgb(0, 23, 67), rgb(13, 57, 147)) !important;
            background-size: 100% !important;
            background-position:  50% 50% !important;
            
        }

        .navbar-responsive-header h2 {
            background: linear-gradient(rgb(255, 255, 255), rgb(226, 226, 226)) !important;
            background-clip: text !important;
        }


        .img-placeholder-responsive {
            height: 290px !important;
        }

    }

    @media screen and (min-width:0px) and ( max-width: 992px) {
                .fix-responsive-text{
                    display: grid;
                    height:30px !important;
                }
                .mobile-responsive{
                    display: grid !important;
                }

                .general-responsive {
                    display:none !important;
                }

                .ajust-responsive {
                    grid-template-columns: 45% 45% !important;
                    position: fixed;
                    height: 60px !important;
                    width: 100%;
                    top: 0px;
                    z-index: 9999999999 !important;
                }              
    }
    .pr-3 {
        padding-right: 10px !important; 
    }

    .pl-3 {
        padding-left:  10px !important;
    }

    .ajust-responsive {
        
        position: fixed;
        height: 60px !important;
        width: 100%;
        top: 0px;
        z-index: 9999999999 !important;
    }

    @media screen and (min-width:993px) and (max-width:8000px) {
                        .mobile-responsive{
                            display: none !important;
                        }

                        .general-responsive {
                            display:grid !important;
                        }
    }



    /* ///// aqui vamos a colocar los estilos de los botones  con su respecti va animacion //// */

    .box-icon-toggle {
        display: grid !important;
        grid-template-columns: 100%;
        width: 35px !important;
        height: 35px !important;
        border:1px solid rgba(255, 255, 255, 0.204);
        margin-left: auto !important;
        border-radius: 1px !important;
        margin-right: 15px !important;
        align-items: center;
        box-shadow: -2px 2px 1px rgb(12, 31, 156),0px 0px 4px rgb(0, 26, 92)!important;
        cursor: pointer !important;
        transition: all 200ms ease;
        position: relative;
        
        
    }

    .objeto1{
        top:20%;
        transition: all 200ms ease;
    }

    .objeto2{
        top:50%;
        transition: all 200ms ease;
    }

    .objeto3{
        top:80%;
        transition: all 200ms ease;
    }

    .box-icon-toggle .active1 {
        opacity: 1;
        transition: all 200ms ease;
        top:50%;
        transform: rotate(-45deg);

    }

    .box-icon-toggle .active2 {
        opacity: 0;
        position: absolute;
        transition: all 200ms ease;
        top:2px !important;
    }

    .box-icon-toggle .active3{
        opacity: 1;
        position: absolute;
        transition: all 200ms ease;
        top:50%;
        transform: rotate(45deg);
    }


    .box-icon-toggle span {
            width: 24px !important;
            height: 1px !important;
            background: #fff;
            position: absolute;
            left:6px;
            
    }

    /* ///// aqui vamos a colocar los estilos de los botones  con su respecti va animacion //// */

    .z-yeah {
        opacity:0.40;
        opacity: 0.20;
        animation: joeenter 2000ms ease-in-out 0ms infinite alternate;
        
    }

    .z-yeah-2 {
        opacity:0.40;
        opacity: 0.20;
        animation: joeenterdos 2000ms ease-in-out 0ms infinite alternate;
        
    }

    
@keyframes joeenterdos {
    0% {
            opacity: 0.20;       
    }
    40% {
        opacity: 0.10;
        
    }
    100% {
       opacity: 0.20;
    }
}




@keyframes joeenter {
        0% {
                opacity: 0.40;       
        }
        40% {
            opacity: 0.10;
            
        }
        100% {
           opacity: 1;
        }
}


.beauty-joema-alert {
    display: grid;
    height: 100vh;
    width: 100vw;
    
    position: fixed;
    z-index: 999999;
    justify-content: center;
    grid-auto-flow: row;
    grid-template-rows: 15% 5%;
    padding-top: 36vh;
    font-size: 25px;
    
    
    color:rgb(255, 255, 255);
    animation: openwindowcool 300ms ease-in-out 0ms 1 normal;
        

}

.beauty-joema-alert h3 {
    width: 60%;
    min-width: 400px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    animation: openwindowcool-2 2700ms ease-in-out 0ms infinite alternate;
    
}

@media screen and (min-width:0px) and (max-width:992px) {
    .beauty-joema-alert  {
        padding-top: 16vh;
        grid-template-rows: 50% 6%;
        
    }
    .beauty-joema-alert h3 {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        
    }
}

@keyframes openwindowcool-2 {
    from {
        
        opacity: 1;
    }
    to {
        
        opacity: 0.70;
    }
}

@keyframes openwindowcool {
    from {
        
        opacity: 0;
    }
    to {
        
        opacity: 1;
    }
}


.beauty-joema-alert::after{
    height: 100%;
    width: 100%;
    position: absolute;
    content:'';
    top: 0px;
    left: 0px;
    background: rgba(24, 39, 209, 0.839);
    z-index:-1;
} 

.btn-continuar-alerta:hover {
    text-shadow: 0px 0px 12px #fff;
    transition: all 200ms ease;
    border: 1px solid #fff;
}

.btn-continuar-alerta {
    border-style: none;
    padding: 1px 40px;
    cursor: pointer;
    border-radius: 1px;
    color:#fff;
    background: rgba(255, 255, 255, 0.031);
    z-index:0;
    border: 1px solid rgba(255, 255, 255, 0.276);
    /* width: 140px; */
    font-size: 18px;
    text-shadow: 0px 0px 12px rgba(255, 255, 255, 0.196);
    transition: all 200ms ease;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100px;
}

.beauty-joema-alert::before{
    height: 100%;
    width: 100%;
    position: absolute;
    content:'';
    top: 0px;
    left: 0px;
    
    background:linear-gradient(to right top,rgba(0, 0, 0, 0.84),#000000de);
    /* background: linear-gradient(to left top, #e2e1e100,#fff, rgba(255, 255, 255, 0.87), #fffffff0); */
    z-index:0;
}



/* 
.bg-particle-media-window::before{
    height: 100%;
    width: 100%;
    position: absolute;
    content:'';
    top: 0px;
    left: 0px;
    background: linear-gradient(to right top, rgba(0, 0, 0, 0.437), #00082425);
    background: linear-grdient(to left top, #e2e1e100,#fff, rgba(255, 255, 255, 0.87), #fffffff0);
    
    
} */

 
.bg-particle-media-window #tsparticles {
    display: grid;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    filter: hue-rotate( 310deg) saturate(400%);
    animation: hueblue 5000ms ease 0ms infinite alternate;
    z-index: 1;
  }

  .img-svg-out {
    display: grid;
    width: auto;
    height: 150%;
    
    position: fixed;
    top:-40%;
    right: 10%;
    filter: blur(0px);
    opacity: 0.40;
    animation: joeenterwowcrack 400ms ease-in-out 0ms 1 normal;
    

  }

  
  .img-svg-out-2 {
    display: grid;
    width: auto;
    height: 160%;
    
    position: fixed;
    top:10%;
    left: 10%;
    filter: blur(90px);
    animation: joeenterwow 5000ms ease-in-out 0ms infinite alternate;

  }


  @keyframes joeenterwowcrack {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.40;
    }
  }
  





@keyframes joeenterwow {
    0% {
            opacity: 0.40;       
            
    }
    40% {
        opacity: 0.10;
        
        
    }
    100% {
       opacity: 1;
       
    }
}

  



.span-loader-joema-alert-group {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 10px;
    position:fixed;
    bottom:55%;
    left:0%;
    height:30px;
    width:10px;
    animation: leve 4000ms ease-in-out 0ms infinite alternate-reverse;
    opacity: 0;
}

.aceptar span {
    background: linear-gradient( to right, rgb(48, 130, 231), rgba(0, 0, 0, 0));
}

 .noaceptar span {
    background: linear-gradient( to right, rgb(231, 48, 124), rgba(0, 0, 0, 0));
}

@keyframes leve {
    from{
        width:10%;
        opacity: 0;
    }
    to {
        width:40%;
        opacity: 1;
    }
}

.special-day {
    font-size: 9px !important; 
    text-shadow: 0px 0px 2px #fff;
}

.black-beu{
    background: #000 !important;
}

@media screen and ( min-width: 0px) and (max-width: 992px){
    .directive-corporative {
        display: grid;
        grid-template-columns: 100% !important;
    }

}

.BoxTittle {
    padding-left: 7vw;
    padding-right: 7vw;
}
 

.directive-corporative {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    justify-content: center;
    column-gap: 2%;
}

.directive-corporative .input-element {
    padding: 0px !important;
}

.tit-directores {
    display: block;
    background: #022e52;
    color: #fff;
    width: 100%;
    position: relative;
    border-radius: 2px;
    padding: 7px 5px 7px 5px;
    margin-top: 10px;
}

.ben-corporative-own {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
} 
.pb-0 {
    padding-bottom: 0px !important;
}

.ayudaloader{
    display: grid;
    grid-auto-columns: 100%;
    row-gap: 18px;
   
  }


  .ayudaloader p {
    font-size: 20px !important;
    line-height: 20px;
  }

  .set-width{
    max-width: 131px;
width: 100%;
margin: 0px auto;
  } 


  .set-width-dos {
    max-width: 91px;
width: 100%;
margin: 0px auto;
align-items: center;
  }

.max-width-100 {
    max-width: 100% !important;
}


.bar-members-yeah {
    background:rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    position: relative;
    height: 100%;
    width: 300px;
    right: 0;
    padding: 30px;
    }

    .dad-community {
        display: grid;
        grid-template-columns:  50% 30% !important;
       
    }

    .origen-dos {
        display: grid;
     margin-left: auto;
    }


    .nav-bar-domo-2 {
        position: absolute !important;
        height: 100% !important;
        background: linear-gradient( to right, #1a013f, #1a013f);
        color: #fff !important;
        width: 280px !important ;
        top:0px;
        background-size: cover !important;
    }

    .nav-bar-domo-2 p {
        color:#fff;
    }


    .active-btn-domo-com {
        background: #E5E8EE12 !important;
        position: relative;
        transition: all 450ms ease;
      }



    .color-comun {
        background: #1a013f !important;
    } 


    .beba {
        display: grid;
        grid-template-columns: 100%;
        width: 100%;
    }

    .beba-titulo {
        width: 120px !important;
    }


    .bg-domo-joema-option-66 {
        background:linear-gradient( to left, #413f71, #1c1a2b);
        padding-left: 19px;
        padding-bottom: 10%;
        color: #fff;
      } 

      .new-feed-interfase {
        background: transparent !important;
        color: #fff !important;
       
      }

      .new-feed-interfase h4 {
        letter-spacing: 3px;
        color: #fff !important;
        font-size: 40px !important;
      }

      .new-feed-interfase p {
  
        color: #fff !important;
        
        font-size: 16px !important;
      }

      .magik-gau {
        background: linear-gradient( to left , #3d2d5000, #1a044a21);
        padding: 30px;
        border-radius: 4px;
        color: #fff;
        position: relative;
      }

      .add-group-comunidad {
        position: absolute;
        width: 30% !important;
        background: #0a0a0a62;
        z-index: 999;
        right: 0 !important;
        bottom: 0;
        border-radius: 10px;
        margin: 20px;
        padding: 20px;
        animation: animacion-inicial 900ms ease 0ms 1 normal;
        transition: all 200ms ease;

      }


      @keyframes animacion-inicial {
        0%{
            opacity: 0;
            transition: all 200ms ease;
        }
        0%{
            opacity: 1;
            transition: all 200ms ease;
        }
      }

      .bg-domo-joema-option-66 h2{
        color: #fff !important;  
      } 

      .bg-domo-joema-option-66 h4{
        color: #fff !important;  
      } 

      .bg-domo-joema-option-67 {
        background:linear-gradient( to left bottom, #7f25bc, #220054);
        padding-left: 19px;
        padding-bottom: 10%;
        color: #fff;
      } 


      .bg-domo-joema-option-67 h2{
        color: #fff !important;  
      } 

      .bg-domo-joema-option-67 h4{
        color: #fff !important;  
      } 

      .el-nene h4 {
            white-space:nowrap;
            font-weight:300 !important;
      }

      .el-nene {
        color:#fff;
        display:grid;
        grid-template-columns:35% 45%;
      }
      
      .elhola{
        padding:30px 19px !important;
        border-radius: 10px 0px 0px 10px;
        margin-left: 20px;
      }

      .elhola p {
        border-left:1px solid #ffffff2b;
        margin-left:5px;
        padding-left:5px;
        color: #f0f0ff;
        text-shadow: 0px 0px 4px #ffffff1a;
      }

      .bar-order-action {
        display:grid;
        grid-template-columns: 33% 33% 33%;
        column-gap:10px;
      } 

      .list-menu-icon-set-33 p {
        border-left:1px solid #ffffff2b;
        margin-left:5px;
        padding-left:5px;
        color: #f0f0ff;
        text-shadow: 0px 0px 4px #ffffff1a;
        text-align:center;
      }

      .list-menu-icon-set-33  {
        display: grid !important;
        grid-template-columns: 40% 60% !important;
        justify-content:center;
        background:#ffffff0f;
        padding:10px;
        border-radius:5px;
        
      }

      .bg-cool-domo {
        background:linear-gradient(to left, rgb(46, 45, 85), rgb(93, 83, 155)) !important;
      }

      .list-menu-icon-set-33 span {
        display: grid !important;
        justify-content:center;
      } 

      .bebebe {
        display: grid !important;
        grid-template-columns: 100%;
        align-items: center;
        height: 200px !important;
        width: 100% !important;
        background:#fff !important;
      }